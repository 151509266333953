<template>
    <div
        class="page"
        :class="[
            { 'take-out': pageType == 1 },
            { 'show-notice': noticeConfig.msg },
            { 'show-swiper': !isHideSwiper && imgUrls.length },
            { 'show-search': showSearch },
        ]"
    >
        <!-- 显示卡包 给page的class添加 'show-card-bag'  -->
        <div class="error-notice" :class="{ hidden: !noticeConfig.msg }" ref="errorNotice">
            <img class="img" src="@/assets/icons/icon-notice.png" />
            <div class="baffle-before"></div>
            <div class="swiper-container notice-swiper" ref="msgWidth">
                <div
                    class="swiper-wrapper"
                    style="
                        transition-timing-function: linear;
                        -webkit-transition-timing-function: linear;
                    "
                >
                    <div
                        class="swiper-slide"
                        v-for="index of noticeConfig.maxPageIndex"
                        :key="index"
                    >
                        <div class="swiper-msg" :style="{ marginLeft: '-' + (index - 1) + '00%' }">
                            {{ noticeConfig.msg }}
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
            <div class="msg" ref="noticeMsg">{{ noticeConfig.msg }}</div>
            <div class="baffle-after"></div>
        </div>
        <div class="flash" ref="flash" v-if="imgUrls.length > 0">
            <div class="swiper-container banner-swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in imgUrls" :key="index">
                        <img class="slide-image" :src="item.imageUrl" alt="" />
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <div class="head">
            <img class="img" src="@/assets/icons/icon-store.png" />
            <div class="position-title">{{ storeName }}</div>
            <div class="positon-address">{{ address }}</div>
            <img
                class="love"
                @click.stop="switchFavorite"
                :src="
                    favorite
                        ? require('@/assets/icons/icon-start-02.png')
                        : require('@/assets/icons/icon-start-01.png')
                "
            />
        </div>
        <div class="main">
            <div class="tab-left iphone-x-adaptation" @scroll="menuScroll">
                <div class="box-bg">
                    <div class="menu-list">
                        <div class="box default" :class="{ 'show-b-r-b-r': menuCurrent == 0 }">
                            <div class="anchor" id="menu-0"></div>
                        </div>
                        <div
                            class="box"
                            :class="[
                                { select: menuCurrent == index },
                                { 'show-b-r-b-r': menuCurrent == index + 1 },
                                { 'show-b-r-t-r': menuCurrent == index - 1 },
                            ]"
                            v-for="(item, index) in queryData"
                            @click="switchMenu(index)"
                            :key="index"
                        >
                            <div class="before"></div>
                            <img class="logo" v-lazy="item.imageUrl" />
                            <div class="name" v-html="item.name"></div>
                            <div class="anchor" :id="'menu-' + (index + 1)"></div>
                        </div>
                        <div
                            class="box default"
                            :class="{ 'show-b-r-t-r': menuCurrent == queryData.length - 1 }"
                        ></div>
                    </div>
                    <div class="padding-bottom"></div>
                </div>
            </div>
            <div class="tab-right">
                <div class="render iphone-x-adaptation" @scroll="renderScroll">
                    <div :id="'pro-0'"></div>
                    <div
                        v-for="(proList, proIndex) in queryData"
                        class="render-list"
                        :id="proIndex != 0 ? 'pro-' + proIndex : ''"
                        :key="proIndex"
                    >
                        <div class="box-title" :class="{ first: proIndex == 0 }">
                            <div class="value">{{ proList.name | proListNameFilter }}</div>
                        </div>
                        <KfcMenuItem
                            v-for="(item, index) in proList.products"
                            :item="item"
                            @showProductDetail="showProductDetail"
                            @reduceNum="reduceNum"
                            @plusNum="plusNum"
                            :key="index"
                            v-show="item.isShow || true"
                        ></KfcMenuItem>
                    </div>
                    <div class="padding-bottom"></div>
                </div>
            </div>
        </div>
        <!-- 多sku选择 start -->
        <div class="dialoing" v-if="isdDialog">
            <div class="bac-opacity" @click="isdDialog = false"></div>
            <div class="con">
                <div class="box iphone-x-adaptation-margin-bottom">
                    <div class="close" @click="isdDialog = false"></div>
                    <div class="product-img">
                        <img
                            :src="
                                dialogQuery.items[selectSize].productImageUrl ||
                                dialogQuery.productImageUrl
                            "
                            alt=""
                        />
                    </div>
                    <div class="title-name">{{ dialogQuery.productName }}</div>
                    <!-- <div class="type-name">规格</div> -->
                    <div class="content">
                        <div
                            class="max-name max-style"
                            :class="{ 'max-color': index == selectSize }"
                            v-for="(item, index) in dialogQuery.items"
                            :key="index"
                            @click="hancleSkuPrice(index)"
                        >
                            {{ item.specDesc || item.productName }}
                        </div>
                    </div>
                    <div class="quantity-line">
                        <div class="price">
                            {{ dialogTotal
                            }}{{
                                dialogXiaoBuDianIntegral
                                    ? '+' + dialogXiaoBuDianIntegral + '积分'
                                    : ''
                            }}{{
                                dialogJiMiWuXianIntegral
                                    ? '+' + dialogJiMiWuXianIntegral + '能量'
                                    : ''
                            }}
                        </div>
                        <div class="choose-name">
                            {{
                                dialogQuery.items[selectSize].specDesc ||
                                dialogQuery.items[selectSize].productName
                            }}
                        </div>
                        <div class="num-box">
                            <img
                                class="btn-reduce"
                                src="@/assets/icons/icon-circular-white-reduce.png"
                                @click="dialogReduce"
                            />
                            <div class="quantity">{{ dialogQuery.items[selectSize].num }}</div>
                            <img
                                class="btn-plus"
                                src="@/assets/icons/icon-circular-plus.png"
                                :class="{
                                    max:
                                        dialogQuery.items[selectSize].num >=
                                            dialogQuery.items[selectSize].num.maxQty &&
                                        dialogQuery.items[selectSize].maxQty != -1,
                                }"
                                @click="dialogPlus"
                            />
                        </div>
                    </div>
                    <div class="btn-add-cart" @click="addCart">加入购物车</div>
                </div>
            </div>
        </div>
        <!-- 多sku选择 end -->
        <!-- 底部菜单  start -->
        <div
            class="cart-box iphone-x-adaptation"
            :class="[{ 'open-cart': boolSee, 'not-product': priceData.allNum == 0 }]"
        >
            <div class="cart-line">
                <div class="coupon-box">
                    <div
                        class="content"
                        @click="(couponCardDialogStatus = true) && (waitUseCardList = [])"
                    >
                        <div class="title">优惠助手</div>
                        <div class="coupon-count" v-if="cardCount > 0">{{ cardCount }}张卡券</div>
                    </div>
                </div>
                <div class="cart-price">
                    <div class="content">
                        <div class="price-box" @click="switchSee">
                            <div class="product-count" v-if="priceData.allNum > 0">
                                {{ priceData.allNum }}
                            </div>
                            <div class="price">
                                <div class="text">
                                    ¥{{
                                        $store.getters.getExposeMethod.showOriginalPrice
                                            ? priceData.originalPrice
                                            : priceData.price
                                    }}
                                </div>
                                <div
                                    class="monery-discount"
                                    v-if="$store.getters.getExposeMethod.customizedXiaoBuDian"
                                >
                                    +{{ priceData.xiaoBuDianIntegral }}积分
                                </div>
                                <div
                                    class="monery-discount"
                                    v-else-if="$store.getters.getExposeMethod.customizedJiMiWuXian"
                                >
                                    +{{ priceData.jiMiWuXianIntegral }}能量
                                </div>
                                <div
                                    class="monery-discount"
                                    v-else-if="
                                        priceData.saveMoney > 0 &&
                                        !$store.getters.getExposeMethod.showOriginalPrice
                                    "
                                >
                                    已省¥{{ priceData.saveMoney }}元
                                </div>
                            </div>
                            <template v-if="$store.state.common.config.kfcTakeoutDeliveryFee == 0">
                                <div class="delivery-fee">
                                    免配送费
                                    <span class="line-through">
                                        另加配送费 {{ QZ_KFC_DEFAULT_DELIVERY_FEE }} 元
                                    </span>
                                </div>
                            </template>
                            <template
                                v-else-if="
                                    $store.state.common.config.kfcTakeoutDeliveryFee >=
                                    QZ_KFC_DEFAULT_DELIVERY_FEE
                                "
                            >
                                <div class="delivery-fee">
                                    另加配送费
                                    {{ $store.state.common.config.kfcTakeoutDeliveryFee }} 元
                                </div>
                            </template>
                            <template v-else>
                                <div class="delivery-fee">
                                    另加配送费
                                    {{ $store.state.common.config.kfcTakeoutDeliveryFee }} 元
                                    <span class="line-through">
                                        {{ QZ_KFC_DEFAULT_DELIVERY_FEE }}元
                                    </span>
                                </div>
                            </template>
                        </div>
                        <div class="submit-btn" @click="addCommit">
                            <div class="title">选好了</div>
                            <div class="tip">Order</div>
                        </div>
                        <div class="no-select-product-tip" v-if="priceData.allNum == 0">
                            未选购商品
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部菜单 end -->
        <!-- 购物车 start -->
        <div class="cart-dialog" v-if="boolSee">
            <div class="bac" @click="switchSee"></div>
            <div class="contant iphone-x-adaptation">
                <div class="cart-head">
                    <div class="title">已选餐品</div>
                    <div class="btn-clear" @click="clearCart">清空</div>
                </div>
                <div class="box-list">
                    <template>
                        <div
                            class="box"
                            :class="[
                                { favour: 5 == item.activityType },
                                { voucher: 10 == item.activityType },
                            ]"
                            v-for="(item, index) in onLineShopCardList"
                            :key="index"
                        >
                            <img :src="item.productImageUrl" class="img" />
                            <div class="name">
                                <p class="p">{{ item.productName }}</p>
                            </div>
                            <div class="price">
                                <div class="sale">
                                    {{ item.salePrice
                                    }}{{
                                        item.xiaoBuDianIntegral
                                            ? '+' + item.xiaoBuDianIntegral + '积分'
                                            : ''
                                    }}{{
                                        item.jiMiWuXianIntegral
                                            ? '+' + item.jiMiWuXianIntegral + '能量'
                                            : ''
                                    }}
                                </div>
                                <div class="original">¥{{ item.originPrice }}</div>
                            </div>
                            <div class="icon-click">
                                <img
                                    class="btn-reduce"
                                    src="@/assets/icons/icon-circular-white-reduce.png"
                                    @click="reduceOnLineCart(item, 1)"
                                />
                                <div class="quantity">{{ item.quantity }}</div>
                                <img
                                    class="btn-plus"
                                    src="@/assets/icons/icon-circular-plus.png"
                                    :class="{
                                        max: item.quantity >= item.maxQty && item.maxQty != -1,
                                    }"
                                    @click="addOnLineCart(item, 1)"
                                />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!-- 购物车 end -->
        <div class="error-msg" :class="{ hidden: isOk }">
            <div class="error-box">
                <img class="img" src="@/assets/kfc-img/stop-msg-icon.png" alt="" srcset="" />
                <div class="msg">美味维护中，订餐请稍后～</div>
            </div>
        </div>
        <!-- 搜索页面 start -->
        <div class="search-box">
            <input type="text" @focus="searchBoxFocus" v-model="searchText" placeholder="搜餐品" />
            <div class="close" v-show="searchText.length" @click="searchText = ''"></div>
        </div>
        <div class="search-page" v-if="showSearch">
            <div class="header">
                <div class="btn-cancel" @click="cancelSearch">取消</div>
            </div>
            <div class="content iphone-x-adaptation">
                <template v-if="searchText.length == 0">
                    <div class="title">热门</div>
                    <div class="product-name-list">
                        <div class="item" @click="clickSearchHot('薯条')">薯条</div>
                        <div class="item" @click="clickSearchHot('黄金鸡块')">黄金鸡块</div>
                        <div class="item" @click="clickSearchHot('新奥尔良烤翅')">新奥尔良烤翅</div>
                        <div class="item" @click="clickSearchHot('香辣鸡翅')">香辣鸡翅</div>
                        <div class="item" @click="clickSearchHot('翅桶')">翅桶</div>
                        <div class="item" @click="clickSearchHot('原味鸡')">原味鸡</div>
                        <div class="item" @click="clickSearchHot('新奥尔良烤鸡腿堡')">
                            新奥尔良烤鸡腿堡
                        </div>
                        <div class="item" @click="clickSearchHot('蛋挞')">蛋挞</div>
                        <div class="item" @click="clickSearchHot('老北京鸡肉卷')">老北京鸡肉卷</div>
                        <div class="item" @click="clickSearchHot('圣代')">圣代</div>
                    </div>
                    <template v-if="historySearchList.length">
                        <div class="title">历史</div>
                        <div class="product-name-list">
                            <div
                                class="item"
                                v-for="(item, index) in historySearchList"
                                :key="index"
                            >
                                {{ item }}
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="render iphone-x-adaptation">
                        <div class="search-padding-top"></div>
                        <div
                            v-for="(proList, proIndex) in queryData"
                            class="render-list"
                            :key="proIndex"
                        >
                            <KfcMenuItem
                                v-for="(item, index) in proList.products"
                                v-show="item.isShow"
                                :item="item"
                                @showProductDetail="showProductDetail"
                                @reduceNum="reduceNum"
                                @plusNum="plusNum"
                                :key="index"
                            ></KfcMenuItem>
                        </div>
                        <div class="no-product" v-if="!isHasProduct"></div>
                        <div class="search-padding-bottom"></div>
                    </div>
                </template>
            </div>
        </div>
        <!-- 搜索页面 end -->
        <!-- 快速预览卡包 start -->
        <div
            class="coupon-card-dialog"
            @click="couponCardDialogStatus = false"
            v-if="couponCardDialogStatus"
        >
            <div class="content" @click.stop>
                <div class="title">优惠助手</div>
                <div class="close" @click="couponCardDialogStatus = false"></div>
                <div class="coupon-card">
                    <CouponCard
                        :componentType="2"
                        :setWaitUseCardList="waitUseCardList"
                        :takeout="takeout"
                        :addressId="+addressId"
                        :setStoreCode="storeCode"
                        @callback="couponUseCallback"
                    ></CouponCard>
                </div>
            </div>
        </div>
        <!-- 快速预览卡包 end-->
        <!-- 卡包悬浮按钮 start -->
        <!-- <div class="card-bag" @click="toCard">
            <img src="~@/assets/icons/icon-card-bag.png" class="img" />
            <div class="text" v-if="cardCount == 0">我的卡包</div>
            <div class="text" v-else>
                卡包
                <span class="span">{{ cardCount }}</span>
                张
            </div>
        </div> -->
        <!-- 卡包悬浮按钮 end -->
    </div>
</template>
<script>
import {
    GET_BANNER_OR_ICON,
    GET_STOP_MENU,
    GET_STOP_FAVORITE,
    SET_LOVE_STOP,
    GET_KFC_STORE_NOTICE,
    GET_OUT_BY_STOP_MENU,
    VALID_TAKEOUT_ORDER,
    GET_CART_LIST,
    ADD_PRODUCT_FOR_CART,
    REDUCE_PRODUCT_FOR_CART,
    CLEAR_CART_LIST,
    GET_USER_AVAILABLE_CARD_COUNT,
    USE_KFC_CARD,
} from 'api';
import {
    PAGE_RESTAURANT_LIST,
    PAGE_ORDER_CREATE,
    PAGE_RESTAURANT_SELECT,
    PAGE_ORDER_INFO,
    PAGE_PRODUCT_DETAIL,
    PAGE_ADDRESS_LIST,
    PAGE_KFC_RESTAURANT_CARD,
} from 'page';
import Swiper from '@/lib/swiper3.4.2/swiper.min.js';
import '@/lib/swiper3.4.2/swiper.min.css';
import {
    QZ_KFC_CART_NAME,
    QZ_KFC_PRODUCT_SEARCH_LIST,
    QZ_KFC_PRODUCT_INFO,
    QZ_KFC_DEFAULT_DELIVERY_FEE,
} from 'constant';
import number from '@/lib/number';
import moment from 'moment';
import KfcMenuItem from './kfcMenuItem.vue';
import CouponCard from '@/views/kfc/card/index.vue';
export default {
    data() {
        return {
            pageType: 0,
            addressId: 0,
            isInit: false,
            firstCurrent: 0,
            current: 0,
            menuCurrent: -2,
            queryData: [],
            imgUrls: [],
            // 本地购物车
            selectProductList: [],
            // 线上购物车
            onLineShopCardList: [],
            storeName: '',
            storeCode: '',
            address: '',
            bannerSwiper: null,
            flashHeight: '0px',
            // model
            isdDialog: false,
            dialogQuery: {},
            dialogTotal: 0,
            dialogXiaoBuDianIntegral: 0,
            dialogJiMiWuXianIntegral: 0,
            selectSize: 0,
            // 底部订单
            priceData: {
                price: 0,
                oldPrice: 0,
                saveMoney: 0,
                allNum: 0,
                originalPrice: 0,
                xiaoBuDianIntegral: 0, // 小步点 积分
                jiMiWuXianIntegral: 0, // 疾米无限 能量
            },
            // 购物车
            boolSee: false,
            favorite: false, //是否收藏
            noticeConfig: {
                msg: '',
                maxPageIndex: 0,
            },
            isOk: true,
            showSearch: false,
            historySearchList: [],
            searchText: '',
            isHasProduct: true,
            isHideSwiper: false,
            menuScrollTop: 0,
            proScrollTop: 0,
            cardCount: 0,
            couponCardDialogStatus: false,
            waitUseCardList: [],
            lovelyProductIds: [],
            QZ_KFC_DEFAULT_DELIVERY_FEE: QZ_KFC_DEFAULT_DELIVERY_FEE,
        };
    },
    watch: {
        current(val) {
            console.log(val <= this.firstCurrent ? 'menu-' + this.firstCurrent : 'menu-' + val);
            document
                .getElementById(
                    val <= this.firstCurrent ? 'menu-' + this.firstCurrent : 'menu-' + val
                )
                .scrollIntoView({
                    // behavior: "smooth"
                });

            this.menuCurrent = val;

            let offsetTop = document.getElementById(
                val <= this.firstCurrent ? 'pro-0' : 'pro-' + val
            ).offsetTop;
            document.getElementsByClassName('render')[0].scroll({
                top: offsetTop,
            });
        },
        selectProductList(val) {
            if (val.length == 0) {
                this.boolSee = false;
            }
        },
        showSearch(val) {
            if (val) {
                this.historySearchList = JSON.parse(
                    window.localStorage.getItem(QZ_KFC_PRODUCT_SEARCH_LIST) || '[]'
                );
            }
        },
        searchText(val) {
            let isHasProduct = false;
            let HashSet = {};
            let hashType10Set = {};
            for (let i = 0; i < this.queryData.length; i++) {
                const classItem = this.queryData[i];
                for (let j = 0; j < classItem.products.length; j++) {
                    const item = classItem.products[j];
                    if (item.productName.indexOf(val) > -1) {
                        item.isShow = false;
                        if (item.productId && !HashSet[item.productId]) {
                            HashSet[item.productId] = true;
                            item.isShow = true;
                        } else if (!item.productId && item.type === 10) {
                            let key = item.specGroups.map(v => v.productId).join('|');
                            if (!hashType10Set[key]) {
                                item.isShow = true;
                                hashType10Set[key] = true;
                            }
                        } else if (
                            !item.productId &&
                            item._productId &&
                            !HashSet[item._productId]
                        ) {
                            HashSet[item._productId] = true;
                            item.isShow = true;
                        }
                        isHasProduct = true;
                    } else if (item.items && item.items.length > 0) {
                        let isShow = false;
                        let showIndex = -1;
                        for (let k = 0; k < item.items.length; k++) {
                            const v = item.items[k];
                            if (v.productName.indexOf(val) > -1 && !HashSet[item.productId]) {
                                isShow = true;
                                isHasProduct = true;
                                showIndex = k;
                                HashSet[item.productId] = true;
                                break;
                            }
                        }
                        item.isShow = isShow;
                        item.showIndex = showIndex;
                    } else {
                        item.isShow = false;
                    }
                }
            }
            this.isHasProduct = isHasProduct;
        },
    },
    components: {
        KfcMenuItem,
        CouponCard,
    },
    computed: {
        originalPrice() {
            return this.pageType == 1
                ? number.operation(
                      `${this.priceData.originalPrice}+${this.$store.state.common.config.kfcTakeoutDeliveryFee}`
                  )
                : this.priceData.originalPrice;
        },
        takeout() {
            return this.pageType == 1;
        },
    },
    filters: {
        proListNameFilter(val) {
            return val.split('<br>').join('');
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (vm.queryData.length == 0 && !vm.isInit) {
                vm.$util.debounce(vm.init)();
            } else if (
                !vm.isInit ||
                from.path == PAGE_RESTAURANT_SELECT ||
                from.path == PAGE_ORDER_INFO ||
                from.path == PAGE_RESTAURANT_LIST ||
                from.path == PAGE_ADDRESS_LIST
            ) {
                vm.isInit = false;
                vm.$util.debounce(vm.init)();
            } else if (
                (from.path == PAGE_PRODUCT_DETAIL && vm.queryData.length > 0) ||
                from.path == PAGE_ORDER_CREATE
            ) {
                vm.$nextTick(() => {
                    try {
                        document.getElementsByClassName('tab-left')[0].scrollBy({
                            top: vm.menuScrollTop,
                            left: 0,
                        });
                        document.getElementsByClassName('render')[0].scrollBy({
                            top: vm.proScrollTop,
                            left: 0,
                        });
                    } catch (error) {}
                });
                vm.$util.debounce(vm.getOnLineCart)();
            } else if (from.path == PAGE_PRODUCT_DETAIL && vm.queryData.length == 0) {
                vm.$util.debounce(vm.init)();
            }
        });
    },
    created() {
        this.$util.debounce(this.init)();
    },
    methods: {
        async init() {
            if (this.isInit) {
                return;
            }
            this.searchText = '';
            this.isInit = true;
            this.storeName = decodeURIComponent(this.$route.query.storeName);
            this.storeCode = decodeURIComponent(this.$route.query.storeCode);
            this.address = decodeURIComponent(this.$route.query.address);
            this.addressId = this.$route.query.addressId || 0;
            this.pageType = this.$route.query.type || 0;
            this.lovelyProductIds = JSON.parse(this.$route.query.productIds || '[]');

            this.getUrlImg();
            this.getNotice();
            await this.getData();
            this.getCardCount();

            let waitUseCardList = this.$store.getters.waitUseCardList;
            if (waitUseCardList && waitUseCardList.length > 0) {
                this.waitUseCardList = waitUseCardList;
                this.couponCardDialogStatus = true;
                this.$store.commit('setWaitUseCardList', []);
            }

            if (this.pageType == 0) {
                this.getFavorite();
                this.$util.debounce(this.getOnLineCart)();
            }
        },
        // 获取卡券数量
        getCardCount() {
            this.$util
                .get(GET_USER_AVAILABLE_CARD_COUNT, {
                    takeout: this.takeout,
                })
                .then(res => {
                    if (res.success) {
                        this.cardCount = res.data.totalCount;
                    }
                });
        },
        // 获取购物车信息
        getOnLineCart() {
            this.$util
                .get(GET_CART_LIST, {
                    storeCode: this.storeCode,
                    takeout: this.takeout,
                })
                .then(res => {
                    if (res.success) {
                        this.setOnLineCart(res);
                    }
                });
        },
        setOnLineCart(res) {
            if (this.$store.getters.getExposeMethod.customizedXiaoBuDian) {
                for (let i = 0; i < res.data.cart.items.length; i++) {
                    const item = res.data.cart.items[i];
                    let reduce = number.operation(`${item.originPrice}-${item.salePrice}`);
                    item.xiaoBuDianIntegral = +number.operation(`${reduce}*20`);
                }
            } else if (this.$store.getters.getExposeMethod.customizedJiMiWuXian) {
                for (let i = 0; i < res.data.cart.items.length; i++) {
                    const item = res.data.cart.items[i];
                    let reduce = number.operation(`${item.originPrice}-${item.salePrice}`);
                    item.jiMiWuXianIntegral = +number.operation(`${reduce}*10`);
                }
            }
            this.onLineShopCardList = res.data.cart.items;
            this.priceData.allNum = res.data.cart.quantity;
            this.priceData.price = res.data.cart.salePrice;
            this.priceData.originalPrice = res.data.cart.originPrice;
            this.priceData.saveMoney = res.data.cart.saveAmount;
            let reduce = number.operation(
                `${res.data.cart.originPrice}-${res.data.cart.salePrice}`
            );
            this.priceData.xiaoBuDianIntegral = +number.operation(`${reduce}*20`);
            this.priceData.jiMiWuXianIntegral = +number.operation(`${reduce}*10`);
            if (this.onLineShopCardList.length == 0) {
                this.boolSee = false;
            }
            this.asynOnLineCartToProductList();
        },
        // 同步商品列表
        asynOnLineCartToProductList() {
            if (this.queryData.length > 0) {
                for (let i = 0; i < this.queryData.length; i++) {
                    const productList = this.queryData[i].products;
                    for (let j = 0; j < productList.length; j++) {
                        const item = productList[j];
                        item.id = null;
                        var quantity = 0;
                        for (let k = 0; k < this.onLineShopCardList.length; k++) {
                            const v = this.onLineShopCardList[k];
                            if (
                                (item.productId == v.productId &&
                                    item.accountId == v.accountId &&
                                    item.accountType == v.accountType) ||
                                (item.productId == v.productId && v.accountType == 5)
                            ) {
                                quantity += v.quantity;
                            }
                        }
                        if (quantity > 0) {
                            item.num = quantity;
                        } else {
                            item.num = 0;
                        }
                    }
                }
            }
        },
        // 获取通知信息
        getNotice() {
            this.$util.get(GET_KFC_STORE_NOTICE).then(res => {
                if (res.success && res.data.length > 0) {
                    this.noticeConfig.msg = res.data[0].content;
                    this.$nextTick(() => {
                        this.noticeConfig.maxPageIndex = Math.ceil(
                            this.$refs.noticeMsg.clientWidth / this.$refs.msgWidth.clientWidth
                        );
                        this.$nextTick(() => {
                            let m = new Swiper('.notice-swiper', {
                                autoplay: 1, //可选选项，自动滑动
                                speed: 5000,
                                loop: true, //可选选项，开启循环
                            });
                        });
                    });
                }
            });
        },
        // 获取是否收藏
        getFavorite() {
            this.$util.get(`${GET_STOP_FAVORITE}/${this.storeCode}`).then(res => {
                if (res.success) {
                    this.favorite = res.data;
                }
            });
        },
        // 切换收藏
        switchFavorite() {
            this.favorite = !this.favorite;
            this.$util
                .post(SET_LOVE_STOP, {
                    storeCode: this.storeCode,
                    favorite: this.favorite,
                })
                .then(res => {
                    if (!res.success) {
                        this.favorite = !this.favorite;
                    }
                });
        },
        // 获取banner
        getUrlImg() {
            var currentTime = moment();
            var day = currentTime.day();
            var hour = currentTime.hours();
            var minute = currentTime.minute();
            if (hour >= 7 && hour <= 9) {
                // this.imgUrls = [{
                //     imageUrl: "https://qianzhu-img.oss-cn-hangzhou.aliyuncs.com/kfc-banner/1630395005665.png"
                // }];
            }
            this.imgUrls &&
                this.$nextTick(() => {
                    this.bannerSwiper = new Swiper('.banner-swiper', {
                        autoplay: 3000, //可选选项，自动滑动
                        loop: true, //可选选项，开启循环
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                    });
                });
        },
        // 获取菜单
        getData() {
            return new Promise((resolve, reject) => {
                let requestUrl = '';
                let requestData = {};
                if (this.pageType == 0) {
                    requestUrl = GET_STOP_MENU;
                    requestData = {
                        storeCode: this.storeCode,
                    };
                    if (this.addressId) {
                        requestData.menuType = 3;
                    }
                    this.$util.eventStatistics({
                        title: 'BrowseMenu',
                    });
                } else if (this.pageType == 1) {
                    this.$util.eventStatistics({
                        title: 'BrowseTakeoutMenu',
                    });
                    requestUrl = GET_OUT_BY_STOP_MENU;
                    requestData = {
                        addressId: this.addressId,
                    };
                }
                this.$util.loading();
                this.$util.post(requestUrl, requestData).then(res => {
                    this.$util.unloading();
                    if (res.success) {
                        let tempProductMenuList = [];

                        if (1 == this.pageType) {
                            tempProductMenuList = res.data.menuCategories;
                            this.storeCode = res.data.storeCode;
                            this.$util.debounce(this.getOnLineCart)();
                        } else if (0 == this.pageType) {
                            tempProductMenuList = res.data;
                        }

                        if (this.menuCurrent == -2) {
                            this.menuCurrent = 0;
                        }

                        // 猜你喜欢
                        if (this.lovelyProductIds.length > 0) {
                            let lovelyProductList = [];
                            for (let i = 0; i < this.lovelyProductIds.length; i++) {
                                const item = this.lovelyProductIds[i];

                                let isSelectProduct = false;
                                for (let j = 0; j < tempProductMenuList.length; j++) {
                                    const productsItem = tempProductMenuList[j];
                                    for (let k = 0; k < productsItem.products.length; k++) {
                                        const proItem = productsItem.products[k];
                                        // console.log(proItem.productId, this.lovelyProductIds);
                                        if (item == Number(proItem.productId)) {
                                            lovelyProductList.push(proItem);
                                            isSelectProduct = true;
                                            break;
                                        } else if (proItem.type == 10 && proItem.specGroups) {
                                            console.log(proItem);
                                            for (let l = 0; l < proItem.specGroups.length; l++) {
                                                const specItem = proItem.specGroups[l];
                                                if (item == Number(specItem.productId)) {
                                                    lovelyProductList.push(proItem);
                                                    isSelectProduct = true;
                                                    break;
                                                }
                                            }
                                        }
                                        // console.log(proItem.productId);
                                    }
                                    if (isSelectProduct) {
                                        break;
                                    }
                                }
                            }

                            tempProductMenuList.unshift({
                                id: 999999,
                                sNewPage: false,
                                name: '猜你喜欢',
                                self: true,
                                imageUrl: require('@/assets/icons/icon-lovely.png'),
                                products: lovelyProductList,
                            });
                        }

                        let current = -1;
                        for (let i = 0; i < tempProductMenuList.length; i++) {
                            const proItem = tempProductMenuList[i];
                            let customizedXiaoBuDian =
                                this.$store.getters.getExposeMethod.customizedXiaoBuDian;
                            let customizedJiMiWuXian =
                                this.$store.getters.getExposeMethod.customizedJiMiWuXian;
                            if (proItem.products.length > 0 && current == -1) {
                                current = i;
                            }
                            for (let j = 0; j < proItem.products.length; j++) {
                                const item = proItem.products[j];
                                item.maxQty = item.maxQty || -1;
                                try {
                                    if (item.type == 1) {
                                        //单品
                                        item.showReduceBtn = true;
                                        item.real_price = item.price;
                                        item.reduce_price = number.operation(
                                            `${item.originalTotalPrice}-${item.real_price}`
                                        );
                                    } else if (item.type == 2 && this.pageType == 0) {
                                        // 多规格
                                        item.showReduceBtn = false;
                                        item._productId = `${i}-${j}`;
                                        item.real_price = item.items[0].price;
                                        item.reduce_price = number.operation(
                                            `${item.originalTotalPrice}-${item.price}`
                                        );

                                        for (let j = 0; j < item.items.length; j++) {
                                            const v = item.items[j];
                                            v.num = 0;
                                        }
                                    } else if (item.type == 2 && this.pageType == 1) {
                                        item.showReduceBtn = false;
                                        item._productId = `${i}-${j}`;
                                        item.real_price = item.price;
                                        item.reduce_price = number.operation(
                                            `${item.originalTotalPrice}-${item.price}`
                                        );
                                    } else if (item.type == 3 && this.pageType == 0) {
                                        //套餐
                                        item.showReduceBtn = true;
                                        var price = 0;
                                        item.items.forEach(res => {
                                            price += number.operation(`${res.price}*${1}`);
                                        });
                                        item.real_price = number.operation(
                                            `${item.price}+${price}`
                                        );
                                        item.reduce_price = number.operation(
                                            `${item.originalTotalPrice}-${item.real_price}`
                                        );
                                    } else if (item.type == 3 && this.pageType == 1) {
                                        item.showReduceBtn = false;
                                        item._productId = `${i}-${j}`;
                                        item.real_price = item.price;
                                        item.reduce_price = number.operation(
                                            `${item.originalTotalPrice}-${item.price}`
                                        );
                                    } else if (item.type == 10) {
                                        item._productId = `${i}-${j}`;
                                        item.real_price = item.price;
                                        item.reduce_price = number.operation(
                                            `${item.originalTotalPrice}-${item.price}`
                                        );
                                        if (item.firstActivity) {
                                            item.firstActivityReducePrice = number.operation(
                                                `${item.originalTotalPrice}-${item.firstActivity.price}`
                                            );
                                        }
                                        if (item.specGroups && item.specGroups.length > 0) {
                                            // 多规格
                                            item.showReduceBtn = false;
                                            item.items = item.specGroups;
                                            for (let j = 0; j < item.items.length; j++) {
                                                const v = item.items[j];
                                                v.num = 0;
                                            }
                                        } else {
                                            // 单品
                                            item.showReduceBtn = true;
                                        }
                                    }
                                    if (customizedXiaoBuDian) {
                                        item.xiaoBuDianIntegral = +number.operation(
                                            `${item.reduce_price}*20`
                                        );
                                    } else if (customizedJiMiWuXian) {
                                        item.jiMiWuXianIntegral = +number.operation(
                                            `${item.reduce_price}*10`
                                        );
                                    } else {
                                        item.xiaoBuDianIntegral = 0;
                                        item.jiMiWuXianIntegral = 0;
                                    }
                                    item.num = 0;
                                    item._proIndex = i;
                                    item._index = j;
                                } catch (error) {
                                    console.log(error);
                                }
                            }
                        }

                        this.queryData = tempProductMenuList;
                        this.firstCurrent = current;
                        this.asynOnLineCartToProductList();
                        this.setTotal();
                        this.$nextTick(() => {
                            document.getElementById('menu-' + current).scrollIntoView();
                            this.current = current;
                            for (let i = 0; i < this.queryData.length; i++) {
                                const item = this.queryData[i];
                                item.offsetTop = document.getElementById('pro-' + i).offsetTop;
                            }
                        });
                    } else if (!res.success && res.code == 8001) {
                        this.isOk = false;
                    } else {
                        this.queryData = [];
                        this.setTotal();
                    }
                    resolve(res);
                });
            });
        },
        menuScroll(e) {
            this.menuScrollTop = e.target.scrollTop + 1;
        },
        // 商品滑动
        renderScroll(e) {
            let scrollTop = e.target.scrollTop + 1;
            this.proScrollTop = scrollTop;
            if (scrollTop > 200) {
                this.isHideSwiper = true;
            } else {
                this.isHideSwiper = false;
            }
            let current = 0;
            let isOk = false;
            for (let i = 0; i < this.queryData.length; i++) {
                const item = this.queryData[i];
                if (scrollTop <= item.offsetTop) {
                    current = i;
                    isOk = true;
                    break;
                }
            }
            if (!isOk) {
                current = this.queryData.length;
            }
            current = current - 1 < 0 ? 0 : current - 1;
            current = current <= this.firstCurrent ? this.firstCurrent : current;
            if (current != this.menuCurrent) {
                this.menuCurrent = current;
                document
                    .getElementById(
                        current <= this.firstCurrent
                            ? 'menu-' + this.firstCurrent
                            : 'menu-' + current
                    )
                    .scrollIntoView({
                        behavior: 'smooth',
                    });
            }
        },
        // 加入购物车
        addCart() {
            this.plusNum(
                this.dialogQuery._proIndex,
                this.dialogQuery._index,
                this.selectSize,
                this.dialogQuery.items[this.selectSize].num
            );
            this.isdDialog = false;
        },
        // 修改价格
        setTotal() {
            if (this.pageType == 0) {
                return;
            }
            let productList = this.selectProductList;
            let price = 0,
                saveMoney = 0,
                allNum = 0;
            for (var k = 0; k < productList.length; k++) {
                var item = productList[k];
                if (!item.showReduceBtn && item.items && item.items.length) {
                    for (var i = 0; i < item.items.length; i++) {
                        var v = item.items[i];
                        allNum = number.operation(`${allNum}+${v.num}`);

                        if (v.firstActivity) {
                            var surplusNum =
                                v.firstActivity.activityCount -
                                v.firstActivity.userTodayPurchasedCount;
                            surplusNum < 0 ? (surplusNum = 0) : true;
                            if (item.num <= surplusNum) {
                                price = number.operation(
                                    `${price}+${number.operation(
                                        v.firstActivity.price + '*' + v.num
                                    )}`
                                );
                                saveMoney = number.operation(
                                    saveMoney +
                                        '+' +
                                        number.operation(
                                            number.operation(
                                                v.originalPrice + '-' + v.firstActivity.price
                                            ) +
                                                '*' +
                                                v.num
                                        )
                                );
                            } else {
                                // 先计算可以优惠的件数
                                price = number.operation(
                                    `${price}+${number.operation(
                                        v.firstActivity.price + '*' + surplusNum
                                    )}`
                                );
                                saveMoney = number.operation(
                                    saveMoney +
                                        '+' +
                                        number.operation(v.reduce_price + '*' + surplusNum)
                                );
                                // 再计算正常销售价格
                                price = number.operation(
                                    `${price}+${number.operation(
                                        v.price + '*' + (v.num - surplusNum)
                                    )}`
                                );
                                saveMoney = number.operation(
                                    saveMoney +
                                        '+' +
                                        number.operation(
                                            number.operation(v.originalPrice + '-' + v.price) +
                                                '*' +
                                                (v.num - surplusNum)
                                        )
                                );
                            }
                        } else {
                            price = number.operation(
                                `${price}+${number.operation(v.price + '*' + v.num)}`
                            );
                            saveMoney = number.operation(
                                `${saveMoney}+${number.operation(
                                    number.operation(v.originalPrice + '-' + v.price) + '*' + v.num
                                )}`
                            );
                        }
                    }
                } else {
                    allNum = number.operation(`${allNum}+${item.num}`);
                    if (item.firstActivity) {
                        var surplusNum =
                            item.firstActivity.activityCount -
                            item.firstActivity.userTodayPurchasedCount;
                        surplusNum < 0 ? (surplusNum = 0) : true;
                        if (item.num <= surplusNum) {
                            price = number.operation(
                                `${price}+${number.operation(
                                    item.firstActivity.price + '*' + item.num
                                )}`
                            );
                            saveMoney = number.operation(
                                saveMoney +
                                    '+' +
                                    number.operation(item.reduce_price + '*' + item.num)
                            );
                        } else {
                            // 先计算可以优惠的件数
                            price = number.operation(
                                `${price}+${number.operation(
                                    item.firstActivity.price + '*' + surplusNum
                                )}`
                            );
                            saveMoney = number.operation(
                                saveMoney +
                                    '+' +
                                    number.operation(item.reduce_price + '*' + surplusNum)
                            );
                            // 再计算正常销售价格
                            price = number.operation(
                                `${price}+${number.operation(
                                    item.price + '*' + (item.num - surplusNum)
                                )}`
                            );
                            saveMoney = number.operation(
                                saveMoney +
                                    '+' +
                                    number.operation(
                                        number.operation(
                                            item.originalTotalPrice + '-' + item.price
                                        ) +
                                            '*' +
                                            (item.num - surplusNum)
                                    )
                            );
                        }
                    } else {
                        price = number.operation(
                            `${price}+${number.operation(item.price + '*' + item.num)}`
                        );
                        saveMoney = number.operation(
                            saveMoney + '+' + number.operation(item.reduce_price + '*' + item.num)
                        );
                    }
                }
            }
            this.priceData = {
                price: (+price).toFixed(2),
                saveMoney: (+saveMoney).toFixed(2),
                allNum: allNum,
                originalPrice: number.operation(`${price}+${saveMoney}`),
            };
            if (this.$store.getters.getExposeMethod.customizedXiaoBuDian) {
                this.priceData.xiaoBuDianIntegral = +number.operation(`${saveMoney}*20`);
            } else if (this.$store.getters.getExposeMethod.customizedJiMiWuXian) {
                this.priceData.jiMiWuXianIntegral = +number.operation(`${saveMoney}*10`);
            } else {
                this.priceData.xiaoBuDianIntegral = 0;
                this.priceData.jiMiWuXianIntegral = 0;
            }
            localStorage.setItem(QZ_KFC_CART_NAME, JSON.stringify(this.selectProductList));
        },
        // sku切换
        hancleSkuPrice(index) {
            this.selectSize = index;
            this.setDialogTotal();
        },
        // 清空购物车
        clearCart() {
            this.$util
                .post(CLEAR_CART_LIST, {
                    storeCode: this.storeCode,
                })
                .then(res => {
                    if (res.success) {
                        this.setOnLineCart(res);
                    }
                });
            // if (this.pageType == 0) {
            //     this.$util
            //         .post(CLEAR_CART_LIST, {
            //             storeCode: this.storeCode,
            //         })
            //         .then(res => {
            //             if (res.success) {
            //                 this.setOnLineCart(res);
            //             }
            //         });
            // } else {
            //     this.selectProductList = [];
            //     for (let i = 0; i < this.queryData.length; i++) {
            //         const classItem = this.queryData[i];
            //         for (let j = 0; j < classItem.products.length; j++) {
            //             const item = classItem.products[j];
            //             item.num = 0;
            //             if (item.type == 2 || item.type == 3) {
            //             } else if (!item.showReduceBtn) {
            //                 for (let k = 0; k < item.items.length; k++) {
            //                     const skutItem = item.items[k];
            //                     skutItem.num = 0;
            //                 }
            //             }
            //         }
            //     }
            //     this.setTotal();
            // }
        },
        // 数量减少
        reduceNum(proIndex, index, select = -1) {
            const item = this.queryData[proIndex].products[index];
            if (item.showReduceBtn) {
                return this.reduceOnLineCart(item, 1);
            } else if (select != -1) {
                return this.reduceOnLineCart(item.items[select], 1);
            }

            // if (this.pageType == 0) {
            //     productReduceNum(this);
            // } else if (this.pageType == 1) {
            //     if (typeof proIndex == 'object') {
            //         const item = this.selectProductList[index];
            //         item.num--;
            //         if (item.num == 0) {
            //             this.selectProductList.splice(index, 1);
            //         }
            //         this.$forceUpdate();
            //         this.setTotal();
            //         return;
            //     } else {
            //         productReduceNum(this);
            //     }
            // }
            // function productReduceNum(_this) {
            //     const item = _this.queryData[proIndex].products[index];
            //     if (_this.pageType == 0 && item.showReduceBtn) {
            //         return _this.reduceOnLineCart(item, 1);
            //     } else if (_this.pageType == 0 && select != -1) {
            //         return _this.reduceOnLineCart(item.items[select], 1);
            //     }
            //     if (!item.showReduceBtn && select != -1) {
            //         for (let i = 0; i < _this.selectProductList.length; i++) {
            //             const v = _this.selectProductList[i];
            //             if (v._productId == item._productId) {
            //                 v.items[select].num--;
            //                 v.num--;
            //                 _this.syncData(v);
            //                 let isDelete = true;
            //                 for (let j = 0; j < v.items.length; j++) {
            //                     const _v = v.items[j];
            //                     if (_v.num > 0) {
            //                         isDelete = false;
            //                     }
            //                 }
            //                 if (isDelete) {
            //                     _this.selectProductList.splice(i, 1);
            //                 }
            //                 _this.$forceUpdate();
            //                 _this.setTotal();
            //                 return;
            //             }
            //         }
            //     } else {
            //         for (let i = 0; i < _this.selectProductList.length; i++) {
            //             const v = _this.selectProductList[i];
            //             if (item.productId == v.productId) {
            //                 v.num--;
            //                 _this.syncData(v);
            //                 if (v.num == 0) {
            //                     _this.selectProductList.splice(i, 1);
            //                 }
            //                 _this.$forceUpdate();
            //                 _this.setTotal();
            //                 return;
            //             }
            //         }
            //     }
            // }
        },
        // 减少线上购物车
        reduceOnLineCart(item, num) {
            let HashSetNum = 0;
            var cartItemId = -1;
            if (item.id) {
                return this.reduceOnLineCartRequest(item, num);
            }
            for (let i = 0; i < this.onLineShopCardList.length; i++) {
                const v = this.onLineShopCardList[i];
                if (v.productId == item.productId) {
                    HashSetNum++;
                    cartItemId = v.id;
                }
            }
            if (HashSetNum == 1) {
                return this.reduceOnLineCartRequest(Object.assign(item, { id: cartItemId }), num);
            } else {
                this.$toast('前往购物车删除');
                this.boolSee = true;
            }
        },
        reduceOnLineCartRequest(item, num) {
            this.$util
                .post(REDUCE_PRODUCT_FOR_CART, {
                    cartItemId: item.id,
                    quantity: num,
                    storeCode: this.storeCode,
                    takeout: this.takeout,
                })
                .then(res => {
                    if (res.success) {
                        this.setOnLineCart(res);
                        // this.getOnLineCart();
                    }
                });
        },
        // 数量增加
        plusNum(proIndex, index, select = -1, num = 1) {
            const item = this.queryData[proIndex].products[index];
            console.log(item);

            if (select != -1) {
                return this.addOnLineCart(item.items[select], num);
            } else if (item.maxQty <= item.num && item.maxQty != -1) {
                return this.$toast('亲～已经加到最多了');
            } else if (item.showReduceBtn) {
                return this.addOnLineCart(item, num);
            } else if (
                select > -1 &&
                item.items[select].maxQty <= item.items[select].num &&
                item.maxQty != -1
            ) {
                return this.$toast('亲～已经加到最多了');
            } else {
                if (!item.showReduceBtn && select == -1 && item.items.length > 3) {
                    this.showProductDetail(item, item._proIndex, item._index);
                } else if (!item.showReduceBtn && select == -1) {
                    this.isdDialog = true;
                    var _dialogQuery = JSON.parse(JSON.stringify(item));
                    for (let i = 0; i < _dialogQuery.items.length; i++) {
                        const v = _dialogQuery.items[i];
                        v.num = 1;
                    }
                    this.dialogQuery = _dialogQuery;
                    this.selectSize = 0;
                    this.setDialogTotal();
                }
            }
        },
        // 添加线上购物车
        addOnLineCart(item, num) {
            console.log('addOnLineCart');
            this.$util.eventStatistics({
                title: 'AddOnLineCart',
            });
            this.$util
                .post(ADD_PRODUCT_FOR_CART, {
                    accountId: item.accountId,
                    accountType: item.accountType,
                    productId: item.productId,
                    quantity: num,
                    storeCode: this.storeCode,
                    takeout: this.takeout,
                })
                .then(res => {
                    if (res.success) {
                        this.setOnLineCart(res);
                        if (!this.takeout && res.data.cart.cardPromotion) {
                            this.quickChangeCoupon(res.data.cart.cardPromotion);
                        }
                        // this.getOnLineCart();
                    }
                });
        },
        // 是否兑换优惠券
        quickChangeCoupon(cardPromotion) {
            this.$confirm.open({
                title: '温馨提示',
                message: `您刚添加的 [<span style="color:#d90209;">${cardPromotion.productName}</span>] 有可用的优惠券，预计可省 <span style="color:#d90209;">${cardPromotion.saveAmount}</span> 元，是否使用？`,
                confirmText: '立即使用',
                cancelText: '下次使用',
                success: () => {
                    this.$util
                        .post('/api/kfcShoppingCart/useCardPromotion', {
                            cartItemId: cardPromotion.cartItemId,
                            code: cardPromotion.cardCode,
                            productId: cardPromotion.productId,
                            storeCode: this.storeCode,
                        })
                        .then(res => {
                            if (res.success) {
                                this.$toast(`替换成功`);
                                this.setOnLineCart(res);
                            }
                        });
                },
            });
        },
        syncData(item) {
            for (let i = 0; i < this.queryData.length; i++) {
                const classItem = this.queryData[i];
                for (let i = 0; i < classItem.products.length; i++) {
                    const v = classItem.products[i];
                    if (v.productId == item.productId) {
                        v.num = item.num;
                    }
                }
            }
        },
        // 左侧菜单切换
        switchMenu(index) {
            this.current = index;
        },
        // 切换店铺
        switchStop() {
            this.$util.pageBack();
            // this.$util.pageJump(PAGE_RESTAURANT_LIST)
        },
        // 查看购物车
        switchSee() {
            if (this.priceData.allNum) {
                this.boolSee = !this.boolSee;
            }
        },
        // 确定订单
        addCommit() {
            let pageData = {
                type: this.pageType,
                storeCode: this.storeCode,
            };
            if (this.addressId) {
                pageData.addressId = this.addressId;
            }
            if (this.onLineShopCardList.filter(v => v.express).length) {
                pageData.type = 2;
            }
            this.$util.pageJump(PAGE_ORDER_CREATE, pageData);
            // localStorage.setItem(QZ_KFC_CART_NAME, JSON.stringify(this.selectProductList));
            // if (this.pageType == 0) {
            //     this.$util.pageJump(PAGE_ORDER_CREATE, {
            //         storeCode: this.storeCode,
            //     });
            // } else {
            //     let items = [];
            //     console.log(items);
            //     for (let i = 0; i < this.selectProductList.length; i++) {
            //         const item = this.selectProductList[i];
            //         items.push({
            //             productId: item.originalProductId || item.productId,
            //             productType: item.type,
            //             productGroupId: item.productGroupId,
            //             quantity: item.num,
            //             condimentRoundList: item.condimentItemList,
            //         });
            //     }
            //     this.$util
            //         .post(VALID_TAKEOUT_ORDER, {
            //             addressId: this.addressId,
            //             items: items,
            //         })
            //         .then(res => {
            //             if (res.success) {
            //                 this.$util.pageJump(PAGE_ORDER_CREATE, {
            //                     addressId: this.addressId,
            //                     type: 1,
            //                 });
            //             }
            //         });
            // }
        },
        // 商品详情
        showProductDetail(item, i, j) {
            let _items = [];
            if (item.optionItems && item.optionItems.length) {
                _items = item.optionItems;
            }

            if (this.pageType == 1 && item.items) {
                _items = item.items;
            }
            // else {
            //     return;
            // }
            window.localStorage.setItem(
                QZ_KFC_PRODUCT_INFO,
                JSON.stringify(
                    Object.assign({}, item, {
                        optionItems: item.optionItems,
                        items: _items,
                        specGroups: item.specGroups || item.items || [],
                        storeCode: this.storeCode,
                        limitActivity: item.limitActivity,
                        purchasedQuantity: item.num,
                        pageType: this.pageType,
                        addressId: this.addressId,
                    })
                )
            );
            this.$util.pageJump(PAGE_PRODUCT_DETAIL);
            // this.$util.pageJump(PAGE_PRODUCT_DETAIL, {
            //     type: item.type,
            //     productId: item.productId,
            //     productName: item.productName,
            //     productImageUrl: item.productImageUrl,
            //     descCn: item.descCn,
            //     price: item.price,
            //     items: JSON.stringify(_items),
            //     specGroups: JSON.stringify(item.specGroups || []),
            //     num: item.num,
            //     maxQty: item.maxQty,
            // })
        },
        // 弹窗减少数量
        dialogReduce() {
            if (this.dialogQuery.items[this.selectSize].num < 2) {
                return;
            }
            this.dialogQuery.items[this.selectSize].num--;
            this.setDialogTotal();
        },
        // 弹窗添加数量
        dialogPlus() {
            if (
                this.dialogQuery.items[this.selectSize].maxQty ==
                this.dialogQuery.items[this.selectSize].num
            ) {
                return this.$toast('亲～已经加到最多了');
            }
            this.dialogQuery.items[this.selectSize].num++;
            this.setDialogTotal();
        },
        // 设置弹窗小计
        setDialogTotal() {
            this.dialogTotal = +number.operation(
                `${this.dialogQuery.items[this.selectSize].num}*${
                    this.dialogQuery.items[this.selectSize].price
                }`
            );
            this.setDialogIntegral();
        },
        // 设置价格后积分
        setDialogIntegral() {
            if (this.$store.getters.getExposeMethod.customizedXiaoBuDian) {
                let reduce = number.operation(
                    `${this.dialogQuery.items[this.selectSize].originalTotalPrice}-${
                        this.dialogQuery.items[this.selectSize].price
                    }`
                );
                this.dialogXiaoBuDianIntegral = +number.operation(
                    `${this.dialogQuery.items[this.selectSize].num * 20}*${reduce}`
                );
            } else if (this.$store.getters.getExposeMethod.customizedJiMiWuXian) {
                let reduce = number.operation(
                    `${this.dialogQuery.items[this.selectSize].originalTotalPrice}-${
                        this.dialogQuery.items[this.selectSize].price
                    }`
                );
                this.dialogJiMiWuXianIntegral = +number.operation(
                    `${this.dialogQuery.items[this.selectSize].num * 20}*${reduce}`
                );
            } else {
                this.dialogXiaoBuDianIntegral = 0;
                this.dialogJiMiWuXianIntegral = 0;
            }
        },
        openSkuText(index) {
            console.log(index);
            if (this.selectProductList[index].openSkuStatus) {
                this.selectProductList[index].openSkuStatus = false;
            } else {
                this.selectProductList[index].openSkuStatus = true;
            }
            console.log(this.selectProductList[index]);
            this.$forceUpdate();
            // productItem.openSkuStatus = !productItem.openSkuStatus
        },
        // 搜索框聚焦
        searchBoxFocus() {
            this.showSearch = true;
        },
        // 取消搜索
        cancelSearch() {
            this.showSearch = false;
            this.searchText = '';
        },
        // 热门搜索
        clickSearchHot(name) {
            this.searchText = name;
        },
        // 前往卡包
        toCard() {
            this.$util.pageJump(PAGE_KFC_RESTAURANT_CARD, {
                type: 1,
                storeCode: this.storeCode,
            });
        },
        // 使用优惠券回调
        couponUseCallback(data) {
            if (data.success) {
                this.getOnLineCart();
                this.getCardCount();
            }
        },
    },
};
</script>

<style lang="less" scoped>
@import url('~@/lib/base.less');
@headHeight: .pxToRem(120) [];
@menuWidth: .pxToRem(150) [];
@footHeight: .pxToRem(88) [];
@noticeHeight: .pxToRem(78) [];
@swiperHeight: .pxToRem(300) [];
@searchBoxHeight: .pxToRem(98) [];
@tabRightHeight: .pxToRem(100) [];
@cardBagHeight: .pxToRem(60) [];

.page {
    width: 100%;
    height: 100%;
    &.take-out {
        .head {
            display: none;
        }
        .main {
            top: 0;
            // height: calc(100vh - @footHeight);
        }
        .tab-right {
            &:after {
                top: 0;
            }
        }
        .cart-box {
            .cart-line {
                .cart-price {
                    .content {
                        .price-box {
                            .delivery-fee {
                                display: unset;
                            }
                        }
                    }
                }
            }
        }
        .bottom {
            .monery-icon {
                margin-top: .pxToRem(12) [];
            }
            .quantity {
                margin-top: .pxToRem(8) [];
            }
            .monery-discount {
                margin-top: .pxToRem(19) [];
            }
            .delivery-fee {
                display: block;
                position: absolute;
                bottom: .pxToRem(10) [];
                left: .pxToRem(120) [];
                height: .pxToRem(28) [];
                line-height: .pxToRem(28) [];
                color: white;
            }
        }
        .search-box {
            top: .pxToRem(20) [];
        }
        &.show-swiper {
            .main {
                top: calc(@swiperHeight);
                // height: calc(100vh - @footHeight);
            }
            .tab-right {
                &:after {
                    top: calc(@swiperHeight);
                }
            }
            .search-box {
                top: calc(@swiperHeight + .pxToRem(20) []);
            }
            &.show-notice {
                .search-box {
                    top: calc(@swiperHeight + @noticeHeight + .pxToRem(20) []);
                }
                .tab-right {
                    &:after {
                        top: calc(@swiperHeight + @noticeHeight);
                    }
                }
            }
        }
    }
    &.show-notice {
        .flash {
            top: @noticeHeight;
        }
        .head {
            top: @noticeHeight;
        }
        .main {
            top: calc(@headHeight + @noticeHeight);
            // height: calc(100vh - @headHeight - @noticeHeight - @footHeight);
        }
        .search-box {
            top: calc(@headHeight + @noticeHeight + .pxToRem(20) []);
        }
        .tab-right {
            &:after {
                top: calc(@headHeight + @noticeHeight);
            }
        }
        .card-bag {
            top: calc(@tabRightHeight + @headHeight + @noticeHeight);
        }
        &.show-swiper {
            .flash {
                top: @noticeHeight;
            }
            .head {
                top: calc(@noticeHeight + @swiperHeight);
            }
            .main {
                top: calc(@headHeight + @noticeHeight + @swiperHeight);
                // height: calc(100vh - @headHeight - @noticeHeight - @swiperHeight - @footHeight);
            }
            .render:after {
                top: calc(@headHeight + @noticeHeight + @swiperHeight);
            }
            .search-box {
                top: calc(@headHeight + @noticeHeight + @swiperHeight + .pxToRem(20) []);
            }
            .tab-right {
                &:after {
                    top: calc(@headHeight + @noticeHeight + @swiperHeight);
                }
            }
            .card-bag {
                top: calc(@tabRightHeight + @headHeight + @noticeHeight + @swiperHeight);
            }
            &.show-search {
                .search-box {
                    top: calc(.pxToRem(20) []);
                }
            }
            &.take-out {
                .main {
                    top: calc(@noticeHeight + @swiperHeight);
                    // height: calc(100vh - @noticeHeight - @swiperHeight - @footHeight);
                }
                .render:after {
                    top: calc(@noticeHeight + @swiperHeight);
                }
            }
            &.show-search {
                .main {
                    top: 0;
                    // height: calc(100vh - @footHeight);
                }
            }
        }
        &.take-out {
            .tab-right {
                &:after {
                    top: calc(@noticeHeight);
                }
            }
            .main {
                top: @noticeHeight;
                // height: calc(100vh - @noticeHeight - @footHeight);
            }
            .search-box {
                top: calc(@noticeHeight + .pxToRem(20) []);
            }
        }
    }
    &.show-swiper {
        .head {
            top: calc(@swiperHeight);
        }
        .main {
            top: calc(@headHeight + @swiperHeight);
            // height: calc(100vh - @headHeight - @swiperHeight - @footHeight);
        }
        .search-box {
            top: calc(@headHeight + @swiperHeight + .pxToRem(20) []);
        }
        .card-bag {
            top: calc(@tabRightHeight + @headHeight + @swiperHeight);
        }
    }
    &.show-search {
        .search-box {
            top: .pxToRem(20) [] !important;
            left: .pxToRem(20) [] !important;
            right: .pxToRem(110) [] !important;
        }
        .search-page {
            z-index: 200;
        }
        .search-padding-bottom {
            display: none;
        }
    }
    &.show-card-bag {
        .card-bag {
            display: flex;
        }
        #pro-0 {
            height: @cardBagHeight;
        }
        .render-list {
            margin-top: -@cardBagHeight;
            padding-top: @cardBagHeight;
        }
    }
}
.error-notice {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: .pxToRem(78) [];
    background: @theme_color_first;
    z-index: 100;
    overflow: hidden;
    .img {
        .base-center-y;
        left: .pxToRem(30) [];
        .sq(32);
        z-index: 10;
    }
    .msg {
        .base-center-y;
        left: .pxToRem(82) [];
        white-space: nowrap;
        line-height: .pxToRem(36) [];
        font-size: .pxToRem(26) [];
        color: white;
        z-index: 1;
        opacity: 0;
    }
    .notice-swiper {
        .base-center-y;
        left: .pxToRem(82) [];
        right: .pxToRem(18) [];
        white-space: nowrap;
        line-height: .pxToRem(36) [];
        font-size: .pxToRem(26) [];
        color: white;
        z-index: 1;
        overflow: hidden;
        .swiper-slide {
            overflow: hidden;
        }
    }
    &.hidden {
        display: none;
    }
}

.error-msg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 210;
    .error-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: .pxToRem(184) [];
        .img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: .pxToRem(122) [];
            height: .pxToRem(116) [];
        }
    }
    &.hidden {
        display: none;
    }
}
.error-msg .error-box .msg {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #333333;
    font-size: .pxToRem(32) [];
    line-height: .pxToRem(44) [];
}
.flash {
    position: absolute;
    top: 0;
    left: 0;
    height: @swiperHeight;
    width: 100%;
    margin: 0 auto;
    transition: all 0.3s;
}

.flash .slide-image {
    height: .pxToRem(300) [];
    width: 100%;
    overflow: hidden;
}

.head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: @headHeight;
    background: white;
    transition: all 0.3s;
    z-index: 100;
    .love {
        position: absolute;
        top: .pxToRem(24) [];
        right: .pxToRem(50) [];
        .sq(32);
    }
    .img {
        position: absolute;
        top: .pxToRem(24) [];
        left: .pxToRem(40) [];
        .sq(32);
    }
    .position-title {
        position: absolute;
        top: .pxToRem(20) [];
        left: .pxToRem(82) [];
        height: .pxToRem(40) [];
        line-height: .pxToRem(40) [];
        font-size: .pxToRem(30) [];
        color: rgba(0, 0, 0, 0.9);
        font-weight: 800;
        width: .pxToRem(560) [];
        .overflow(1);
    }
    .positon-address {
        position: absolute;
        bottom: .pxToRem(20) [];
        left: .pxToRem(40) [];
        height: .pxToRem(36) [];
        line-height: .pxToRem(36) [];
        font-size: .pxToRem(24) [];
        color: rgba(0, 0, 0, 0.5);
        font-weight: 400;
        width: .pxToRem(600) [];
        .overflow(1);
    }
}

.main {
    position: absolute;
    top: @headHeight;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s;
    overflow: hidden;
    background: white;
    z-index: 100;
    .padding-bottom {
        width: 100%;
        height: .pxToRem(168) [];
    }
}

.tab-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: @menuWidth;
    background-color: white;
    .box-bg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: @menuWidth;
        background: white;
        overflow-y: auto;
        overflow-x: unset;
    }
    .menu-list {
        background: #f0f0f0;
    }
    .box {
        width: @menuWidth;
        text-align: center;
        background: white;
        font-size: 0;
        .before {
            width: 100%;
            height: .pxToRem(40) [];
        }
        .logo {
            .sq(64);
        }
        .name {
            margin-top: .pxToRem(12) [];
            font-size: .pxToRem(20) [];
            line-height: .pxToRem(28) [];
            color: rgba(0, 0, 0, 0.5);
            padding-bottom: .pxToRem(36) [];
        }
        .anchor {
            .base-center-x;
            bottom: 0;
            width: 100%;
            height: .pxToRem(70) [];
        }
        &.show-b-r-b-r {
            border-bottom-right-radius: .pxToRem(30) [];
        }
        &.show-b-r-t-r {
            border-top-right-radius: .pxToRem(30) [];
        }
        &.default {
            height: .pxToRem(70) [];
        }
    }
    .select {
        background-color: #f0f0f0;
    }

    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: .pxToRem(70) [];
        background: white;
        border-bottom-right-radius: .pxToRem(40) [];
    }
    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
    }
}

.tab-right {
    position: absolute;
    top: 0;
    left: @menuWidth;
    right: 0;
    bottom: 0;
    width: auto;
    background: #f0f0f0;
    border-top-left-radius: .pxToRem(40) [];
    .render {
        position: absolute;
        top: @searchBoxHeight;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        position: absolute;
        z-index: 1;
        background: #f0f0f0;
    }
    &:after {
        content: ' ';
        position: fixed;
        top: @headHeight;
        left: @menuWidth;
        right: 0;
        height: @tabRightHeight;
        box-shadow: inset 0 .pxToRem(15) [] .pxToRem(15) [] .pxToRem(-20) [] rgba(0, 0, 0, 0.5);
        border-top-left-radius: .pxToRem(40) [];
    }
}

.render {
    verflow: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .render-list {
        text-align: center;
        .box-title {
            height: .pxToRem(74) [];
            .value {
                position: absolute;
                right: .pxToRem(56) [];
                bottom: .pxToRem(14) [];
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                font-size: .pxToRem(24) [];
                color: rgba(0, 0, 0, 0.9);
                &:after {
                    content: ' ';
                    .base-center-y;
                    left: .pxToRem(-64) [];
                    background: url('~@/assets/icons/icon-menu-division.png');
                    background-size: cover;
                    .sq(44);
                }
            }
        }
    }
}

.cart-dialog {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 101;
    .bac {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.4;
        z-index: 102;
    }
    .contant {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: .pxToRem(603) [];
        background-color: #ffffff;
        z-index: 103;
        border-top-left-radius: .pxToRem(40) [];
        border-top-right-radius: .pxToRem(40) [];
        padding-bottom: .pxToRem(148) [];
        .cart-head {
            position: absolute;
            height: .pxToRem(85) [];
            top: 0;
            left: 0;
            right: 0;
            .title {
                position: absolute;
                top: .pxToRem(30) [];
                left: .pxToRem(30) [];
                color: rgba(0, 0, 0, 0.9);
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(30) [];
                font-weight: 600;
            }
            .btn-clear {
                position: absolute;
                top: .pxToRem(32) [];
                right: .pxToRem(30) [];
                color: rgba(0, 0, 0, 0.3);
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                font-size: .pxToRem(24) [];
                &:after {
                    content: ' ';
                    .base-center-y;
                    left: .pxToRem(-32) [];
                    .sq(24);
                    background: url('~@/assets/icons/icon-clear.png');
                    background-size: cover;
                }
            }
        }
        .box-list {
            margin-top: .pxToRem(85) [];
            max-height: .pxToRem(518) [];
            overflow: scroll;
        }
        .box {
            width: 100%;
            height: .pxToRem(168) [];
            line-height: .pxToRem(168) [];
            background-color: #ffffff;
            display: flex;
            justify-content: left;
            .img {
                position: absolute;
                top: .pxToRem(20) [];
                left: .pxToRem(30) [];
                max-width: .pxToRem(165) [];
                max-height: .pxToRem(120) [];
            }
            .name {
                position: absolute;
                top: .pxToRem(20) [];
                left: .pxToRem(225) [];
                right: .pxToRem(216) [];
                line-height: .pxToRem(42) [];
                font-size: .pxToRem(28) [];
                font-weight: 600;
                color: rgba(0, 0, 0, 0.9);
                .overflow(1);
            }
            .price {
                position: absolute;
                left: .pxToRem(225) [];
                bottom: .pxToRem(12) [];
                height: .pxToRem(56) [];
                line-height: .pxToRem(56) [];
                display: flex;
                justify-content: space-between;
                .sale {
                    font-size: .pxToRem(32) [];
                    padding-left: .pxToRem(26) [];
                    color: @theme_color_first;
                    font-weight: 600;
                    &:before {
                        content: '¥';
                        position: absolute;
                        left: .pxToRem(0) [];
                        bottom: .pxToRem(10) [];
                        width: .pxToRem(24) [];
                        text-align: center;
                        font-size: .pxToRem(24) [];
                        height: .pxToRem(36) [];
                        line-height: .pxToRem(36) [];
                    }
                }
                .original {
                    text-decoration: line-through;
                    color: rgba(0, 0, 0, 0.3);
                    padding-left: .pxToRem(12) [];
                    font-size: .pxToRem(24) [];
                }
            }
            .icon-click {
                position: absolute;
                top: .pxToRem(52) [];
                right: .pxToRem(30) [];
                height: .pxToRem(56) [];
            }
            .btn-reduce {
                position: absolute;
                top: 0;
                left: 0;
                .sq(56);
                z-index: 1;
            }
            .quantity {
                height: .pxToRem(56) [];
                line-height: .pxToRem(56) [];
                font-size: .pxToRem(26) [];
                color: rgba(0, 0, 0, 0.9);
                font-size: .pxToRem(26) [];
                padding: 0 .pxToRem(74) [];
            }
            .btn-plus {
                position: absolute;
                top: 0;
                right: 0;
                .sq(56);
                z-index: 1;
                &.max {
                    opacity: 0.4;
                }
            }
            .sku-box {
                float: left;
                margin-top: .pxToRem(66) [];
                margin-bottom: .pxToRem(66) [];
                margin-left: .pxToRem(225) [];
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                background: rgba(0, 0, 0, 0.1);
                width: .pxToRem(300) [];
                overflow: hidden;
                .sku-item {
                    box-sizing: border-box;
                    padding: 0 .pxToRem(10) [];
                    font-size: .pxToRem(22) [];
                    .overflow(1);
                }
                &.open {
                    height: auto;
                    overflow: unset;
                }
            }
            &.show-sku {
                height: auto;
            }
            &:last-child {
                margin-bottom: .pxToRem(14) [];
                &:after {
                    height: 0;
                    width: 0;
                }
            }
            &:after {
                content: ' ';
                position: absolute;
                bottom: 0;
                left: .pxToRem(30) [];
                right: .pxToRem(30) [];
                height: .pxToRem(1) [];
                background: rgba(0, 0, 0, 0.15);
            }
            &.favour {
                .name {
                    p {
                        text-indent: .pxToRem(50) [];
                    }
                    &:before {
                        content: '惠';
                        text-indent: .pxToRem(0) [];
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #d90209;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        width: .pxToRem(40) [];
                        text-align: center;
                        border-radius: .pxToRem(4) [];
                        color: white;
                    }
                }
            }
            &.voucher {
                .name {
                    p {
                        text-indent: .pxToRem(50) [];
                    }
                    &:before {
                        content: '代';
                        text-indent: .pxToRem(0) [];
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #d90209;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        width: .pxToRem(40) [];
                        text-align: center;
                        border-radius: .pxToRem(4) [];
                        color: white;
                    }
                }
            }
        }
    }
}

.bottom {
    width: 100%;
    height: @footHeight;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 201;
    background-color: #000000;
    line-height: @footHeight;
    .num-left {
        width: .pxToRem(540) [];
        height: @footHeight;
        display: flex;
        justify-content: flex-start;
        flex: 1;
        .price-text {
            margin-top: .pxToRem(8) [];
            margin-right: .pxToRem(8) [];
            font-size: .pxToRem(36) [];
            color: white;
            height: .pxToRem(44) [];
            line-height: .pxToRem(44) [];
        }
    }
    .see-icon {
        .sq(44);
        margin: .pxToRem(-2) [] .pxToRem(24) [] 0 .pxToRem(52) [];
        background: red;
        img {
            .base-center;
            .sq(44);
            z-index: 3;
        }
        &:before {
            content: ' ';
            .base-center;
            .sq(72);
            background: #4d4d4d;
            border-radius: .pxToRem(36) [];
            z-index: 2;
        }
        &:after {
            content: ' ';
            .base-center;
            .sq(88);
            background: #000000;
            border-radius: .pxToRem(44) [];
            z-index: 1;
        }
    }
    .num {
        position: absolute;
        top: .pxToRem(-22) [];
        right: .pxToRem(-30) [];
        .sq(32);
        line-height: .pxToRem(32) [];
        text-align: center;
        background-color: @theme_color_first;
        border-radius: 50%;
        font-size: .pxToRem(20) [];
        color: #ffffff;
        z-index: 4;
    }
    .monery-icon {
        font-size: .pxToRem(28) [];
        color: #ffffff;
        margin-top: .pxToRem(24) [];
        height: .pxToRem(44) [];
        line-height: .pxToRem(44) [];
    }
    .quantity {
        margin-top: .pxToRem(22) [];
        font-size: .pxToRem(40) [];
        color: #ffffff;
        height: .pxToRem(44) [];
        line-height: .pxToRem(44) [];
    }
    .monery-discount {
        margin-top: .pxToRem(32) [];
        margin-left: .pxToRem(20) [];
        font-size: .pxToRem(20) [];
        height: .pxToRem(28) [];
        line-height: .pxToRem(28) [];
        color: #ffffff;
    }
    .order-ok {
        position: absolute;
        top: 0;
        right: 0;
        height: @footHeight;
        .button-ok {
            .base-center-y;
            right: .pxToRem(24) [];
            height: .pxToRem(60) [];
            line-height: .pxToRem(60) [];
            // box-sizing: border-box;
            min-width: .pxToRem(150) [];
            padding-left: .pxToRem(20) [];
            padding-right: .pxToRem(20) [];
            background: #4d4d4d;
            text-align: center;
            white-space: nowrap;
            border-radius: .pxToRem(30) [];
        }
        &.is-ok {
            .button-ok {
                color: white;
                background: @theme_color_first;
            }
        }
    }
    .ok {
        width: .pxToRem(12) [];
        height: .pxToRem(21) [];
        margin-bottom: .pxToRem(37) [];
        margin-top: .pxToRem(37) [];
    }
    .delivery-fee {
        display: none;
    }
}

.cart-box {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    .cart-line {
        height: .pxToRem(100) [];
        width: .pxToRem(702) [];
        margin: 0 auto .pxToRem(48) [];
        .coupon-box {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: .pxToRem(144) [];
            margin-left: .pxToRem(50) [];
            background: #333333;
            transform: skewX(-10deg);
            border-radius: 0 .pxToRem(12) [] .pxToRem(12) [] 0;
            .content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                transform: skewX(10deg);
                .title {
                    line-height: .pxToRem(30) [];
                    font-size: .pxToRem(28) [];
                    font-weight: 600;
                    color: white;
                }
                .coupon-count {
                    line-height: .pxToRem(26) [];
                    font-size: .pxToRem(24) [];
                    margin-top: .pxToRem(8) [];
                    color: white;
                    font-weight: 600;
                }
            }

            &::before {
                content: ' ';
                position: absolute;
                left: .pxToRem(-50) [];
                top: 0;
                bottom: 0;
                width: .pxToRem(60) [];
                height: 100%;
                background: #333333;
                transform: skewX(10deg);
                border-radius: .pxToRem(50) [] 0 0 .pxToRem(50) [];
            }
        }
        .cart-price {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: .pxToRem(204) [];
            background-color: #d90209;
            transform: skewX(-10deg);
            margin-right: .pxToRem(50) [];
            border-radius: .pxToRem(12) [] 0 0 .pxToRem(12) [];
            transition-property: left, border-radius;
            transition-duration: 0.1s;
            .content {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                transform: skewX(10deg);
                .price-box {
                    position: absolute;
                    left: 0;
                    right: .pxToRem(100) [];
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    margin-left: .pxToRem(12) [];
                    padding-left: .pxToRem(100) [];
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .product-count {
                        position: absolute;
                        top: .pxToRem(8) [];
                        left: .pxToRem(70) [];
                        background: #333333;
                        color: white;
                        width: .pxToRem(40) [];
                        text-align: center;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        border-radius: 100%;
                        font-size: .pxToRem(24) [];
                        font-weight: 600;
                    }
                    .price {
                        margin-left: .pxToRem(16) [];
                        line-height: .pxToRem(48) [];
                        font-size: .pxToRem(42) [];
                        font-weight: 600;
                        color: white;
                        display: flex;
                        align-items: baseline;
                        .monery-discount {
                            display: none;
                            margin-left: .pxToRem(12) [];
                            height: .pxToRem(32) [];
                            line-height: .pxToRem(32) [];
                            font-size: .pxToRem(24) [];
                        }
                    }
                    .delivery-fee {
                        display: none;
                        margin-left: .pxToRem(16) [];
                        line-height: .pxToRem(28) [];
                        font-size: .pxToRem(26) [];
                        font-weight: 600;
                        color: white;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        .line-through {
                            text-decoration: line-through;
                        }
                    }
                    &::before {
                        content: ' ';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: .pxToRem(100) [];
                        height: .pxToRem(100) [];
                        background: url('~@/assets/icons/icon-cart.webp');
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
                .submit-btn {
                    position: absolute;
                    right: .pxToRem(-20) [];
                    top: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title {
                        line-height: .pxToRem(38) [];
                        font-size: .pxToRem(36) [];
                        color: white;
                        font-weight: 600;
                    }
                    .tip {
                        line-height: .pxToRem(26) [];
                        font-size: .pxToRem(24) [];
                        color: white;
                        font-weight: 600;
                        text-align: center;
                    }
                }
                .no-select-product-tip {
                    line-height: .pxToRem(100) [];
                    margin-left: .pxToRem(112) [];
                    font-size: .pxToRem(36) [];
                    color: white;
                    font-size: 600;
                }
            }
            &::before {
                content: ' ';
                position: absolute;
                right: .pxToRem(-50) [];
                top: 0;
                bottom: 0;
                width: .pxToRem(60) [];
                height: 100%;
                background-color: #d90209;
                transform: skewX(10deg);
                border-radius: 0 .pxToRem(50) [] .pxToRem(50) [] 0;
            }
        }
    }
    &.open-cart {
        .coupon-box {
            left: .pxToRem(2) [];
            top: .pxToRem(2) [];
            bottom: .pxToRem(2) [];
        }
        .cart-line {
            .cart-price {
                left: 0;
                transform: skewX(0deg);
                border-radius: .pxToRem(50) [] 0 0 .pxToRem(50) [];
                .content {
                    transform: skewX(0deg);
                    .price-box {
                        .price {
                            .monery-discount {
                                display: unset;
                            }
                        }
                    }
                }
                &::before {
                    transform: skewX(0deg);
                }
            }
        }
    }
    &.not-product {
        .cart-line {
            .cart-price {
                background-color: #979797;
                .content {
                    .price-box {
                        .price {
                            display: none;
                        }
                        .delivery-fee {
                            display: none !important;
                        }
                    }
                    .submit-btn {
                        display: none;
                    }
                }
                &::before {
                    background-color: #979797;
                }
            }
        }
    }
}

.dialoing {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 205;
    .bac-opacity {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.4;
        z-index: 206;
    }
    .con {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 208;
        pointer-events: none;
        .box {
            width: .pxToRem(650) [];
            background-color: #ffffff;
            border-radius: .pxToRem(40) [];
            text-align: center;
            z-index: 207;
            opacity: 1;
            color: #000000;
            position: relative;
            pointer-events: all;
            overflow: hidden;
            .close {
                position: absolute;
                top: .pxToRem(30) [];
                right: .pxToRem(30) [];
                background: url('~@/assets/icons/icon-login-close.png');
                background-size: cover;
                .sq(32);
                z-index: 10;
            }
            .product-img {
                width: .pxToRem(650) [];
                height: .pxToRem(390) [];
                img {
                    .base-center-x;
                    bottom: 0;
                    max-height: .pxToRem(360) [];
                    max-width: .pxToRem(495) [];
                }
                &:after {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: .pxToRem(1) [];
                    background: rgba(0, 0, 0, 0.15);
                }
            }
            .title-name {
                margin-top: .pxToRem(30) [];
                font-size: .pxToRem(34) [];
                font-weight: 600;
                color: rgba(0, 0, 0, 0.9);
                padding: 0 .pxToRem(32) [];
                text-align: left;
            }
            .type-name {
                font-size: .pxToRem(28) [];
                color: rgba(0, 0, 0, 0.9);
                text-align: left;
                margin-top: .pxToRem(40) [];
                margin-left: .pxToRem(40) [];
            }
            .content {
                padding: 0 .pxToRem(32) [];
                margin-top: .pxToRem(20) [];
                margin-bottom: .pxToRem(24) [];
                max-height: calc(100vh - .pxToRem(1118) []);
                verflow: auto;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                text-align: left;
                .max-name {
                    display: inline-block;
                    height: .pxToRem(60) [];
                    line-height: .pxToRem(60) [];
                    border-radius: .pxToRem(8) [];
                    font-size: .pxToRem(26) [];
                    padding: 0 .pxToRem(20) [];
                    margin-right: .pxToRem(12) [];
                    margin-bottom: .pxToRem(12) [];
                    box-sizing: border-box;
                }
                .max-style {
                    background-color: #ffffff;
                    color: rgba(0, 0, 0, 0.5);
                    border: .pxToRem(1) [] solid rgba(0, 0, 0, 0.5);
                    &.max-color {
                        background: #fdf0f0;
                        color: @theme_color_first;
                        border: .pxToRem(1) [] solid @theme_color_first;
                    }
                }
            }
            .quantity-line {
                width: 100%;
                height: .pxToRem(120) [];
                background: #f2f2f2;
                .price {
                    position: absolute;
                    top: .pxToRem(17) [];
                    left: .pxToRem(40) [];
                    color: @theme_color_first;
                    height: .pxToRem(48) [];
                    line-height: .pxToRem(48) [];
                    font-size: .pxToRem(36) [];
                    padding-left: .pxToRem(24) [];
                    font-weight: 600;
                    &:before {
                        content: '¥';
                        position: absolute;
                        bottom: .pxToRem(2) [];
                        left: 0;
                        font-weight: 400;
                        font-size: .pxToRem(24) [];
                        height: .pxToRem(36) [];
                        line-height: .pxToRem(36) [];
                        width: .pxToRem(24) [];
                        text-align: center;
                    }
                }
                .choose-name {
                    position: absolute;
                    bottom: .pxToRem(20) [];
                    left: .pxToRem(40) [];
                    text-align: left;
                    color: rgba(0, 0, 0, 0.5);
                    font-size: .pxToRem(24) [];
                    height: .pxToRem(36) [];
                    line-height: .pxToRem(36) [];
                    width: .pxToRem(400) [];
                    .overflow(1);
                }
                .num-box {
                    .base-center-y;
                    right: .pxToRem(40) [];
                    height: .pxToRem(56) [];
                    .btn-plus {
                        position: absolute;
                        top: 0;
                        right: 0;
                        .sq(56);
                        z-index: 1;
                    }
                    .btn-reduce {
                        position: absolute;
                        top: 0;
                        left: 0;
                        .sq(56);
                        z-index: 1;
                    }
                    .quantity {
                        height: .pxToRem(56) [];
                        line-height: .pxToRem(56) [];
                        font-size: .pxToRem(26) [];
                        color: rgba(0, 0, 0, 0.9);
                        padding: 0 .pxToRem(74) [];
                    }
                }
            }
            .btn-add-cart {
                height: .pxToRem(90) [];
                line-height: .pxToRem(90) [];
                width: 100%;
                text-align: center;
                background: @theme_color_first;
                color: white;
                font-size: .pxToRem(28) [];
            }
        }
    }
}

.search-box {
    position: fixed;
    top: calc(@headHeight + .pxToRem(20) []);
    left: calc(@menuWidth + .pxToRem(20) []);
    right: .pxToRem(20) [];
    height: .pxToRem(68) [];
    line-height: .pxToRem(68) [];
    background: rgba(0, 0, 0, 0.05);
    border-radius: .pxToRem(44) [];
    z-index: 201;
    transition: all 0.3s;
    input {
        height: .pxToRem(68) [];
        line-height: .pxToRem(68) [];
        width: 100%;
        background: url('~@/assets/icons/icon-search.png') rgba(0, 0, 0, 0);
        background-size: .pxToRem(32) [] .pxToRem(32) [];
        background-repeat: no-repeat;
        background-position: .pxToRem(30) [] .pxToRem(18) [];
        text-indent: .pxToRem(82) [];
    }
    .close {
        .base-center-y;
        right: .pxToRem(20) [];
        .sq(32);
        background: url('~@/assets/icons/icon-search-close.png');
        background-size: cover;
    }
}
.search-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: -1;
    .header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: .pxToRem(100) [];
        z-index: 200;
        background: white;
    }
    .btn-cancel {
        position: absolute;
        top: 0;
        right: 0;
        width: .pxToRem(110) [];
        text-align: center;
        height: .pxToRem(100) [];
        line-height: .pxToRem(100) [];
        color: @theme_color_first;
        font-size: .pxToRem(30) [];
    }
    .content {
        position: absolute;
        top: .pxToRem(100) [];
        left: 0;
        right: 0;
        bottom: 0;
        verflow: auto;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        > .title {
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            color: rgba(0, 0, 0, 0.9);
            font-weight: 600;
            font-size: .pxToRem(30) [];
            margin-top: .pxToRem(40) [];
            margin-bottom: .pxToRem(30) [];
            text-indent: .pxToRem(30) [];
        }
        .product-name-list {
            width: 100%;
            padding-left: .pxToRem(30) [];
            padding-right: .pxToRem(10) [];
            box-sizing: border-box;
            .item {
                display: inline-block;
                height: .pxToRem(60) [];
                line-height: .pxToRem(60) [];
                padding: 0 .pxToRem(22) [];
                box-sizing: border-box;
                border: .pxToRem(1) [] solid rgba(0, 0, 0, 0.5);
                margin-right: .pxToRem(20) [];
                margin-bottom: .pxToRem(20) [];
                border-radius: .pxToRem(8) [];
                font-size: .pxToRem(26) [];
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    .render {
        min-height: 100%;
        background: #f0f0f0;
        .box {
            &:first-child {
                // padding-top: .pxToRem(20) [];
            }
        }
    }
    .no-product {
        .base-center-x;
        top: .pxToRem(284) [];
        .sq(320);
        background: url('~@/assets/images/image-search-bg.png');
        background-size: cover;
        &:after {
            content: '无此餐品';
            .base-center-x;
            bottom: .pxToRem(-60) [];
            height: .pxToRem(44) [];
            line-height: .pxToRem(44) [];
            font-size: .pxToRem(28) [];
            color: rgba(0, 0, 0, 0.5);
        }
    }
    .search-padding-top {
        height: .pxToRem(20) [];
    }
    .search-padding-bottom {
        height: @footHeight;
    }
}

.card-bag {
    display: none;
    position: fixed;
    top: calc(@tabRightHeight + @headHeight);
    right: .pxToRem(0) [];
    width: .pxToRem(160) [];
    height: @cardBagHeight;
    background: #ffffff;
    box-shadow: .pxToRem(3) [] .pxToRem(8) [] .pxToRem(15) [] 0 rgba(0, 0, 0, 0.15);
    border-radius: .pxToRem(30) [] 0 0 .pxToRem(30) [];
    z-index: 100;
    justify-content: center;
    align-items: center;
    .img {
        width: .pxToRem(40) [];
        height: .pxToRem(40) [];
        margin-right: .pxToRem(8) [];
    }
    .text {
        height: .pxToRem(28) [];
        line-height: .pxToRem(28) [];
        font-size: .pxToRem(20) [];
        color: rgba(0, 0, 0, 0.9);
        .span {
            color: #d90209;
        }
    }
}
.coupon-card-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    background: rgba(0, 0, 0, 0.65);
    .content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 80vh;
        background: #f2f2f2;
        border-radius: .pxToRem(20) [] .pxToRem(20) [] 0 0;
        overflow: hidden;
        .title {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: .pxToRem(72) [];
            line-height: .pxToRem(72) [];
            background: white;
            font-size: .pxToRem(28) [];
            font-weight: 600;
            padding-left: .pxToRem(20) [];
        }
        .close {
            position: absolute;
            right: .pxToRem(12) [];
            top: .pxToRem(4) [];
            width: .pxToRem(64) [];
            height: .pxToRem(64) [];
            transform: rotate(-45deg);
            // background: red;
            &::before {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: .pxToRem(4) [];
                width: .pxToRem(32) [];
                background: #333333;
            }
            &::after {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: .pxToRem(32) [];
                width: .pxToRem(4) [];
                background: #333333;
            }
        }
        .coupon-card {
            position: absolute;
            top: .pxToRem(72) [];
            left: 0;
            right: 0;
            bottom: 0;
            overflow: scroll;
        }
    }
}
</style>
<style lang="less" scoped>
@import url('~@/lib/base.less');
@footHeight: .pxToRem(88) [];

.show-original-price {
    .page {
        .bottom {
            .order-ok {
                bottom: 0;
                .button-ok {
                    top: 0;
                    bottom: 0;
                    transform: unset;
                    height: @footHeight;
                    line-height: @footHeight;
                    right: 0;
                    border-radius: 0;
                    .title {
                        margin-top: .pxToRem(8) [];
                        margin-right: .pxToRem(8) [];
                        font-size: .pxToRem(36) [];
                        color: white;
                        height: .pxToRem(44) [];
                        line-height: .pxToRem(44) [];
                    }
                    .text {
                        height: .pxToRem(32) [];
                        line-height: .pxToRem(32) [];
                        font-size: .pxToRem(24) [];
                    }
                }
            }
            .order-ok:not(.is-ok) {
                .button-ok {
                    .title,
                    .text {
                        display: none;
                    }
                }
            }
            .order-ok.is-ok {
                .button-ok {
                    .default {
                        display: none;
                    }
                }
            }
        }
        &:not(.take-out) {
            .bottom {
                .price-text {
                    margin-top: .pxToRem(22) [];
                }
            }
        }
    }
}
</style>
