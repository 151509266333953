<!--
import { measureMemory } from 'vm';
 * @Author: Axs <i@axs.email>
 * @Date: 2022-03-11 10:08:28
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2022-07-19 15:20:24
 * @Description: 
 * @FilePath: /src/views/kfc/restaurant/kfcMenuItem.vue
-->
<template>
    <div
        class="component box"
        :class="[
            { 'has-key': item.key },
            { 'first-activity': item.firstActivity },
            { 'limit-activity': item.limitActivity },
            { 'no-allow-purchase': !isAllowPurchase && item.num == 0 },
        ]"
        @click="showProductDetail(item, item._proIndex, item._index)"
    >
        <img
            src="@/assets/icons/icon-first-activity.png"
            v-if="item.firstActivity"
            class="icon-first-activity"
        />
        <img class="left" v-lazy="item.productImageUrl" referrerPolicy="no-referrer" />
        <div class="right">
            <div class="name">{{ item.productName }}</div>
            <span class="reduced integral" v-if="item.xiaoBuDianIntegral">
                {{ '+' + item.xiaoBuDianIntegral + '积分' }}
            </span>
            <span class="reduced integral" v-else-if="item.jiMiWuXianIntegral">
                {{ '+' + item.jiMiWuXianIntegral + '能量' }}
            </span>
            <div class="reduced" v-else-if="item.reduce_price > 0">
                <template v-if="item.firstActivity">
                    已减{{ item.firstActivityReducePrice }}元
                </template>
                <template v-else>已减{{ item.reduce_price }}元</template>
            </div>
            <div
                class="money-title"
                :class="{ 'show-rise': item.items && item.items.length > 0 }"
                v-if="!item.firstActivity"
            >
                <div class="icon-money">¥</div>
                <div class="num" v-if="item.type != 2 && item.type != 10">
                    {{ item.real_price }}
                </div>
                <div class="num" v-if="item.type == 2 || item.type == 10">{{ item.price }}</div>
                <div class="icon-money start" v-if="item.items && item.items.length > 0">起</div>
            </div>
            <div class="first-price" v-if="item.firstActivity">
                <div class="title">首单</div>
                <div class="price">{{ item.firstActivity.price }}</div>
                <div class="original-price" v-if="item.originalTotalPrice">
                    {{ item.originalTotalPrice }}
                </div>
            </div>
            <div
                class="original-price"
                v-if="!item.firstActivity && item.originalTotalPrice && item.reduce_price > 0"
            >
                原价¥{{ item.originalTotalPrice }}
            </div>
            <div
                class="first-price-tip"
                v-if="
                    item.firstActivity &&
                    item.num >=
                        item.firstActivity.activityCount -
                            item.firstActivity.userTodayPurchasedCount
                "
            >
                每天第{{ item.firstActivity.activityCount + 1 }}件¥{{ item.price }}起
            </div>
            <template v-if="isHasSaleTimeHarbinger && isHasSaleTimeEnd">
                <div class="icon-click">
                    <img
                        class="btn-reduce"
                        src="@/assets/icons/icon-circular-reduce.png"
                        v-if="item.num && item.num > 0 && item.showReduceBtn"
                        @click.stop="reduceNum(item._proIndex, item._index)"
                        mode="aspectFit"
                    />
                    <div class="num" v-if="item.num && item.num > 0 && item.showReduceBtn">
                        {{ item.num }}
                    </div>
                    <img
                        v-if="item.type != 2 && item.type != 3 && !item.saleOut"
                        class="btn-plus"
                        :class="{ max: item.num >= item.maxQty && item.maxQty != -1 }"
                        src="@/assets/icons/icon-circular-plus.png"
                        @click.stop="plusNum(item._proIndex, item._index)"
                    />
                </div>
                <div
                    v-if="item.type == 2 || item.type == 3"
                    class="select-sku-btn"
                    :class="{ 'sale-out': item.saleOut }"
                >
                    {{ item.saleOut ? '已售罄' : '选规格' }}
                </div>
                <div class="select-sku-btn sale-out" v-else-if="item.type == 1 && item.saleOut">
                    已售罄
                </div>
            </template>
            <template v-else-if="!isHasSaleTimeHarbinger && isHasSaleTimeEnd">
                <div class="sale-time">
                    <span class="bold">{{ item.limitActivity.beginTime }}</span>
                    <span>优惠开启</span>
                </div>
            </template>
            <template v-else-if="isHasSaleTimeHarbinger && (!isHasSaleTimeEnd || !isAllowPurchase)">
                <div class="sale-time">
                    <span>今日已抢完</span>
                </div>
            </template>
        </div>
        <div class="limit-activity-tip" v-if="item.limitActivity">
            限购
            <br />
            {{ item.limitActivity.userEveryDayCanPurchaseCount }}件
        </div>
        <img class="no-allow-purchase-image" src="@/assets/icons/icon-finished.png" />
    </div>
</template>
<script>
import moment from 'moment';
import number from '@/lib/number';
export default {
    data() {
        return {};
    },
    props: {
        item: {
            type: Object,
            default: {},
        },
    },
    computed: {
        // 是否允许购买
        isAllowPurchase() {
            // 判断限购活动
            if (this.item.limitActivity) {
                if (
                    this.item.limitActivity.totalEveryDayCanPurchaseCount <=
                    this.item.limitActivity.totalTodayPurchasedCount
                ) {
                    return false;
                } else if (
                    this.item.limitActivity.userEveryDayCanPurchaseCount <=
                    this.item.limitActivity.userTodayPurchasedCount
                ) {
                    return false;
                }
            }
            return true;
        },
        isHasSaleTimeHarbinger() {
            if (this.item.limitActivity) {
                if (this.item.limitActivity.beginTime) {
                    let YYYYMMDD = moment().format('YYYY-MM-DD');
                    let startTime = moment(
                        YYYYMMDD + ' ' + this.item.limitActivity.beginTime
                    ).format('x');
                    let currentTime = moment().format('x');
                    if (startTime <= currentTime) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            return true;
        },
        isHasSaleTimeEnd() {
            if (this.item.limitActivity) {
                if (this.item.limitActivity.beginTime) {
                    let YYYYMMDD = moment().format('YYYY-MM-DD');
                    let endTime = moment(
                        YYYYMMDD + ' ' + (this.item.limitActivity.endTime || '23:59:59')
                    ).format('x');
                    let currentTime = moment().format('x');
                    if (currentTime < endTime) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            return true;
        },
    },
    methods: {
        showProductDetail(item, proIndex, index) {
            if (item.saleOut) {
                return this.$toast('已售罄');
            }
            this.$emit('showProductDetail', item, proIndex, index);
        },
        reduceNum(proIndex, index) {
            this.$emit('reduceNum', proIndex, index);
        },
        plusNum(proIndex, index) {
            this.$emit('plusNum', proIndex, index);
        },
    },
};
</script>

<style lang="less" scoped>
@import url('~@/lib/base.less');
.box {
    margin-left: .pxToRem(20) [];
    margin-right: .pxToRem(20) [];
    margin-bottom: .pxToRem(20) [];
    height: .pxToRem(315) [];
    background: #ffffff;
    box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0px rgba(0, 0, 0, 0.1);
    border-radius: .pxToRem(30) [];
    overflow: hidden;
    .left {
        position: absolute;
        bottom: 0;
        left: .pxToRem(25) [];
        max-width: .pxToRem(330) [];
        max-height: .pxToRem(240) [];
    }
    .right {
        position: absolute;
        top: .pxToRem(30) [];
        right: .pxToRem(35) [];
        left: .pxToRem(84) [];
        bottom: 0;
        .name {
            font-size: .pxToRem(30) [];
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9);
            line-height: .pxToRem(40) [];
            text-align: right;
            .overflow(2);
        }
        .reduced {
            position: absolute;
            right: 0;
            bottom: .pxToRem(142) [];
            color: @theme_color_first;
            font-size: .pxToRem(22) [];
            height: .pxToRem(28) [];
            line-height: .pxToRem(28) [];
            &.integral {
                font-size: .pxToRem(24) [];
                font-weight: bold;
            }
        }
        .money-title {
            position: absolute;
            right: 0;
            bottom: .pxToRem(165) [];
            height: .pxToRem(56) [];
            .icon-money {
                position: absolute;
                bottom: .pxToRem(10) [];
                left: 0;
                width: .pxToRem(24) [];
                text-align: center;
                height: .pxToRem(36) [];
                color: @theme_color_first;
                font-size: .pxToRem(24) [];
                white-space: nowrap;
                &.start {
                    left: unset;
                    right: 0;
                    width: auto;
                    bottom: .pxToRem(12) [];
                }
            }
            .num {
                font-size: .pxToRem(36) [];
                font-weight: 600;
                color: @theme_color_first;
                padding-left: .pxToRem(20) [];
                .integral {
                    font-size: .pxToRem(24) [];
                }
            }
            &.show-rise {
                .num {
                    padding-right: .pxToRem(28) [];
                }
            }
        }
        .first-price {
            position: absolute;
            bottom: .pxToRem(181) [];
            right: 0;
            height: .pxToRem(36) [];
            line-height: .pxToRem(36) [];
            background: @theme_color_first;
            color: white;
            border-radius: .pxToRem(8) [];
            .title {
                display: inline-block;
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                padding-left: .pxToRem(9) [];
                padding-right: .pxToRem(5) [];
                font-size: .pxToRem(20) [];
                top: .pxToRem(-5) [];
            }
            .price {
                display: inline-block;
                background: #fdf0f0;
                color: @theme_color_first;
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                font-size: .pxToRem(32) [];
                padding: 0 .pxToRem(10) [];
                border-radius: 0 .pxToRem(8) [] .pxToRem(8) [] .pxToRem(20) [];
                border: .pxToRem(1) [] solid @theme_color_first;
                box-sizing: border-box;
                &:before {
                    content: '¥';
                    font-size: .pxToRem(24) [];
                }
            }
            .original-price {
                .base-center-y;
                left: .pxToRem(-8) [];
                color: rgba(0, 0, 0, 0.3);
                transform: translate(-100%, -50%);
                font-size: .pxToRem(22) [];
                text-align: right;
                &:before {
                    content: '¥';
                }
            }
        }
        .first-price-tip {
            position: absolute;
            bottom: .pxToRem(102) [];
            right: 0;
            height: .pxToRem(28) [];
            line-height: .pxToRem(28) [];
            color: @theme_color_first;
            font-size: .pxToRem(22) [];
        }
        .original-price {
            position: absolute;
            right: .pxToRem(0) [];
            bottom: .pxToRem(100) [];
            text-decoration: line-through;
            color: rgba(0, 0, 0, 0.3);
            font-size: .pxToRem(22) [];
            height: .pxToRem(36) [];
            line-height: .pxToRem(36) [];
        }
        .icon-click {
            position: absolute;
            bottom: .pxToRem(25) [];
            right: 0;
            height: .pxToRem(56) [];
            .btn-reduce {
                position: absolute;
                left: 0;
                top: 0;
                .sq(56);
                z-index: 1;
            }
            .btn-plus {
                position: absolute;
                right: 0;
                top: 0;
                .sq(56);
                z-index: 1;
                &.max {
                    // -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
                    // filter: grayscale(100%);
                    opacity: 0.4;
                }
            }
            .num {
                padding: 0 .pxToRem(68) [];
                height: .pxToRem(56) [];
                line-height: .pxToRem(56) [];
                font-weight: 600;
                color: white;
                background: @theme_color_first;
                border-radius: .pxToRem(28) [];
            }
        }
        .sale-time {
            position: absolute;
            bottom: .pxToRem(25) [];
            right: 0;
            height: .pxToRem(56) [];
            line-height: .pxToRem(56) [];
            padding: 0 .pxToRem(16) [];
            background: rgba(
                red(@theme_color_first),
                green(@theme_color_first),
                blue(@theme_color_first),
                0.2
            );
            border: .pxToRem(1) [] solid @theme_color_first;
            font-size: 600;
            border-radius: .pxToRem(28) [];
            font-size: .pxToRem(24) [];
            color: @theme_color_first;
            white-space: nowrap;
            .bold {
                font-size: .pxToRem(32) [];
                font-weight: bold;
                margin-right: .pxToRem(4) [];
            }
        }
        .select-sku-btn {
            position: absolute;
            bottom: 0;
            right: .pxToRem(-35) [];
            background: @theme_color_first;
            width: .pxToRem(160) [];
            height: .pxToRem(64) [];
            line-height: .pxToRem(64) [];
            color: white;
            border-top-left-radius: .pxToRem(50) [];
            &.sale-out {
                background: rgba(0, 0, 0, 0.3);
            }
        }
    }
    .icon-first-activity {
        position: absolute;
        top: .pxToRem(80) [];
        left: .pxToRem(25) [];
        .sq(88);
        z-index: 10;
    }
    .limit-activity-tip {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        text-align: center;
        .sq(78);
        border-top-left-radius: .pxToRem(30) [];
        border-bottom-right-radius: .pxToRem(30) [];
        background: #d90209;
        font-size: .pxToRem(20) [];
        align-items: center;
        justify-content: center;
        color: white;
    }
    .no-allow-purchase-image {
        display: none;
        position: absolute;
        bottom: .pxToRem(36) [];
        left: .pxToRem(94) [];
        .sq(116);
    }
    &.no-allow-purchase {
        .left {
            filter: opacity(0.5);
        }
        .right {
            .icon-click {
                filter: grayscale(100%);
            }
        }
        .limit-activity-tip {
            filter: grayscale(100%);
        }
        .no-allow-purchase-image {
            display: unset;
        }
    }
    &:last-child {
        margin-bottom: .pxToRem(18) [];
    }
}
</style>
