const number = {
    operation: (text) => {
        // 获取运算符号
        let symbol = '';
        if (text.indexOf('+') > -1) {
            symbol = '+';
        }
        else if (text.indexOf('-') > -1) {
            symbol = '-';
        }
        else if (text.indexOf('*') > -1) {
            symbol = '*';
        }
        else if (text.indexOf('/') > -1) {
            symbol = '/';
        }
        // 转为还字符串并且补齐位数
        let numText1 = text.split(symbol)[0];
        let numText2 = text.split(symbol)[1];
        if (numText1.indexOf('.') == -1) {
            numText1 += '.';
        }
        if (numText2.indexOf('.') == -1) {
            numText2 += '.';
        }
        let polishDecimal1 = numText1.length - numText1.indexOf('.') - 1;
        let polishDecimal2 = numText2.length - numText2.indexOf('.') - 1;
        if (polishDecimal1 > polishDecimal2) {
            for (let i = 0; i < polishDecimal1 - polishDecimal2; i++) {
                numText2 += '0'
            }
        }
        else if (polishDecimal1 < polishDecimal2) {
            for (let i = 0; i < polishDecimal2 - polishDecimal1; i++) {
                numText1 += '0'
            }
        }
        numText1 = Number(numText1.split('.').join(''));
        numText2 = Number(numText2.split('.').join(''));
        // 运算
        let result = null;
        let loopNum = polishDecimal1 > polishDecimal2 ? polishDecimal1 : polishDecimal2;
        if (symbol == '+') {
            result = numText1 + numText2;
        }
        else if (symbol == '-') {
            result = numText1 - numText2;
        }
        else if (symbol == '*') {
            result = numText1 * numText2;
            loopNum *= 2;
        }
        else if (symbol == '/') {
            result = numText1 / numText2;
            loopNum = 0;
        }
        result = result.toString();
        if (loopNum != 0) {
            for (var i = 0; i < loopNum - result.length; i++) {
                result = '0' + result;
            }
            var _numText1 = result.substring(0, result.length - loopNum) || 0;
            var _numText2 = result.substring(result.length - loopNum) || '';
            result = _numText1 + '.' + _numText2;
        }
        return result;
    },
}
export default number;